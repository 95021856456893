<template>
	<div
		class="menu-shade"
		:class="{ open: open }"
	>
		<div
			class="outside"
			:class="{ open: open }"
			@click="closeMenu"
		></div>
		<aside
			class="main-menu"
			:class="{ open: open }"
		>
			<div
				class="menu-container"
				:class="{
					short: shortScreen,
					stuck: stuck,
					bn: site === 'bn',
					fd: site === 'fd',
				}"
			>
				<!-- GARAGE/LOCATION UTILS -->
				<div
					v-if="(isMobile || stuck) && !showingSubMenu"
					class="utils"
				>
					<garage-menu-button
						v-if="garageEnabled"
						:site="siteTag"
						:siteTag="siteTag"
						class="hidden-md hidden-lg"
						@toggle-garage="$emit('toggle-garage')"
						@menu-hovered="$emit('menu-hovered')"
					/>
				</div>

				<!-- SHOP OEM -->
				<div
					v-if="hasStoredRide && !showingSubMenu"
					class="shop-oem"
				>
					<button
						class="btn btn-default btn-block btn-sm"
						@click="goToComponent"
					>
						Shop Sub-Models
					</button>
				</div>

				<!-- INJECTED MENU(S) -->
				<menu-list
					v-if="topItems.length > 0"
					:items="topItems"
					@showingSubMenu="updateShowingSubMenu"
				/>
				<menu-list
					v-if="rideItems.length > 0 && hasStoredRide"
					:items="rideItems"
					@showingSubMenu="updateShowingSubMenu"
				/>
				<menu-list
					:items="items"
					@showingSubMenu="updateShowingSubMenu"
				/>

				<!-- ACCOUNT MENU -->
				<div
					v-if="isAuthenticated && !showingSubMenu"
					class="menu-list"
				>
					<div class="list-title">Account</div>
					<div
						class="list-items"
						v-for="(item, index) in accountLinks"
						:key="index + '_' + item.label"
					>
						<a
							:href="item.url"
							v-html="item.label"
						></a>
					</div>
					<div class="list-separator"></div>
				</div>

				<!-- SITE MENU -->
				<div
					v-if="!showingSubMenu"
					class="menu-list"
				>
					<div class="list-title">Questions?</div>
					<div
						v-for="(item, index) in siteLinks"
						:key="index + '_' + item.label"
						class="list-items"
					>
						<a
							:href="item.url"
							v-html="item.label"
						></a>
					</div>
					<div
						v-if="!isAuthenticated"
						class="list-separator"
					></div>
				</div>

				<!-- SIGN IN -->
				<div
					v-if="!isAuthenticated && !showingSubMenu"
					class="menu-list"
				>
					<div class="list-title">Account</div>
					<div class="list-items">
						<a href="/account/login">Sign In</a>
					</div>
					<div class="list-items">
						<a href="/account/register">Sign Up</a>
					</div>
				</div>

				<!-- MENU BOTTOM SPACING -->
				<div style="height: 80px">&nbsp;</div>
			</div>
		</aside>
	</div>
</template>

<script>
import EventBus from '../../event-bus.js';
import MenuButton from './MenuButton.vue';
import GarageMenuButton from '../garage/GarageMenuButton.vue';
import LocationButton from './LocationButton.vue';
import MenuList from './MenuList.vue';
import garage from '../../mixins/garage.js';

export default {
	name: 'main-menu',

	mixins: [garage],
	components: { MenuButton, GarageMenuButton, LocationButton, MenuList },

	props: {
		items: { type: Array },
		topItems: { type: Array, default: () => [] },
		rideItems: { type: Array, default: () => [] },
		open: { type: Boolean, default: false },
		stuck: { type: Boolean, default: false },
		isMobile: { type: Boolean, default: false },
		accountLinks: { type: Array },
		siteLinks: { type: Array },
		shipping: { default: false },
		site: { type: String, default: 'pz' },
		customerName: { type: String },
		isAuthenticated: { type: Boolean },
		garageEnabled: { type: Boolean, default: false },
		siteTag: { type: String },
	},

	data() {
		return {
			shortScreen: false,
			testItems: [
				{
					title: 'Popular',
					items: [
						{ label: 'Test', url: '#' },
						{ label: 'Test', url: '#' },
						{ label: 'Test', url: '#' },
					],
				},
				{
					title: 'Shop By Brand',
					items: [
						{ label: 'Arctic Cat', url: '#' },
						{ label: 'Can-Am', url: '#' },
						{ label: 'Honda', url: '#' },
						{ label: 'Kawasaki', url: '#' },
						{ label: 'Polaris', url: '#' },
						{ label: 'Sea-Doo', url: '#' },
						{ label: 'Ski-Doo', url: '#' },
						{ label: 'Suzuki', url: '#' },
						{ label: 'Yamaha', url: '#' },
					],
				},
				{
					title: 'Shop By',
					items: [
						{ label: 'Aftermarket Parts', url: '#' },
						{ label: 'Accessories', url: '#' },
						{ label: 'Apparel', url: '#' },
						{ label: 'Tool & Supplies', url: '#' },
						{ label: 'Wheels & Tires', url: '#' },
					],
				},
			],
			showingSubMenu: false,
		};
	},

	watch: {
		open(v) {
			if (v) {
				this.checkForStoredRide();

				if (this.hasStoredRide) this.getStoredRide();
			}
		},
	},

	methods: {
		closeMenu() {
			if (this.open) this.$emit('toggle-menu');
		},
		goToComponent() {
			if (this.garageFitmentId) {
				window.location = `/catalog/v1/${this.garageFitmentId}/${this.storedRideName
					.replace(/[-]/g, '')
					.replace(/\s/g, '-')}`;
			} else {
				window.location = '/search?q=parts&ui=typeahead';
			}
		},
		updateShowingSubMenu(value) {
			this.showingSubMenu = value;
		}
	},

	mounted() {
		this.checkForStoredRide();

		if (this.hasStoredRide) {
			this.getStoredRide();
		}

		const height = window.innerHeight;

		if (height <= 780) this.shortScreen = true;
	},
};
</script>

<style lang="scss">
.shop-oem {
	margin: 2em 0 1em;
	padding: 0 1em;
	button {
		font-weight: 700;
		text-transform: uppercase;
	}
}
.menu-shade {
	position: absolute;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	pointer-events: none;

	transition: background-color 0.1s ease-in-out;

	z-index: 99;

	&.open {
		background-color: rgba(0, 0, 0, 0.45);
		pointer-events: all;
	}

	aside.main-menu {
		z-index: 101;

		// position: absolute;
		height: 100vh;
		background: white;

		width: 320px;
		max-width: 320px;

		transform: translateX(-320px);
		transition: transform 0.2s ease-in-out;

		/* width */
		::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		::-webkit-scrollbar-track {
			background: #f3f3f3;
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: black;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background: #777;
		}

		&.open {
			transform: translateX(0);
		}

		.welcome {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1em 0.5em;
			color: black;
			background-color: #e3e3e3;

			h2 {
				font-weight: 200;
				font-size: 22px;
			}

			.close-button {
				display: flex;
				align-items: center;
				padding: 0.5em;

				margin: 3px;

				border: none;
				outline: 0;
				background-color: transparent;

				&:hover {
					background: rgba(255, 255, 255, 0.3);
				}
			}

			&.pz {
				background-color: #c3161c;
			}

			&.bn {
				background-color: #3777bc;
			}

			&.fd {
				background-color: #33ac00;
			}
		}

		.menu-container {
			height: 85%;

			overflow-y: scroll;

			&.stuck {
				height: 85%;
			}

			&.short {
				height: 70%;

				&.stuck {
					height: 80%;
				}
			}

			div.utils {
				margin-top: 1.5em;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				hr {
					color: transparent;
					width: 85%;
					margin: 0;
				}

				.garage-menu {
					margin-right: 0;

					.garage-menu-button {
						width: 100%;
						border-radius: 0;
						padding: 0.5em;

						&.bn {
							.button-body {
								color: #3777bc;
								background: #fff;
							}
							&:hover {
								.button-body {
									color: darken(#3777bc, 15%);
									background: #fff;
								}
							}
						}

						&.pz {
							background: #fff;
							.button-body {
								background: #fff;
								color: #222;
								transition: all 0.3s ease-in-out;
							}
							&:hover {
								.button-body {
									background: #fff;
									color: #c3161c;
								}
							}
						}

						div.button-body {
							color: black;
						}

						&:hover {
							div.button-body {
								color: white;
							}
						}
					}
				}

				.location {
					margin-left: 0;

					.location-button {
						width: 100%;
						border-radius: 0;
						padding: 1em;
						color: black !important;

						.button-body {
							width: 100%;
						}

						&:hover {
							.location-label {
								color: white !important;
							}
						}

						.location-label {
							color: black;
						}
					}
				}
			}

			&.bn {
				.menu-list {
					.list-items {
						a:hover {
							background-color: #3777bc;
						}
					}
				}
			}

			&.fd {
				.menu-list {
					.list-items {
						a:hover {
							background-color: #33ac00;
						}
					}
				}
			}

			ul {
				list-style: none;
				padding: 0 !important;
				margin: 0;
				transition: all 0.2s ease-in-out;
				li {
					&.separator {
						margin: 0;
					}
				}
			}

			.menu-list {
				transition: all 0.2s ease-in-out;
				.list-title {
					color: black;
					font-size: 1.8rem;
					font-weight: bold;
					text-transform: uppercase;
					padding: 0.5em 1em;
					&.text-sm {
						font-size: 1.5rem;
					}
					&.bold {
						font-weight: 800;
					}
					.list-separator {
						margin: 0 0.5em 1em;
					}
				}

				.list-sub-title {
					color: black;
					font-size: 1.5rem;
					font-weight: bold;
					text-transform: uppercase;
					padding: 0.5em 1em;
				}

				.list-items {
					display: flex;
					flex-direction: column;

					&:first-child {
						margin-top: 1em;
					}

					a,
					button {
						border-radius: 0;
						display: block;
						font-size: 1.5rem;
						font-weight: 600;
						text-decoration: none;
						transition: all 100ms ease-in-out;
						padding: 0.5em 1.5em;
						width: 100%;
						&.back-btn,
						&.start-over-btn  {
							display: flex;
							align-items: center;
							gap: 0.5rem;
							justify-content: flex-start;
							padding: 0.5em 1em;
							&:hover {
								gap: 0.15rem;
							}
						}
						&.start-over-btn {
							margin-left: auto;
							justify-content: flex-end !important;
							&:hover {
								padding: inherit 2em inherit 0;
							}
						}
						&:hover {
							background-color: #c3161c;
							color: white;
							padding: inherit 0 inherit 2em;
							text-decoration: none;
						}
					}
				}

				div.list-separator {
					margin: 1em 2em;
					border-bottom: 1px solid #e4e4e4;
				}
			}
		}
	}

	div.outside {
		width: 100%;
		height: 100%;
		position: absolute;
		pointer-events: none;

		&.open {
			pointer-events: all;
		}
	}
}

@media (max-width: 991px) {
	div.menu-shade {
		aside.main-menu {
			&.open {
				width: 35vw;
				max-width: 35vw;
			}

			div.menu-container {
				height: 87% !important;

				.menu-list {
					.list-items {
						a,
						button {
							padding: 1em 1.5em;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	div.menu-shade {
		aside.main-menu {
			&.open {
				width: 80vw;
				max-width: 80vw;
			}

			div.menu-container {
				height: 87% !important;

				.menu-list {
					.list-items {
						a,
						button {
							padding: 1em 1.5em;
						}
					}
				}
			}
		}
	}
}
</style>
