<template>
  <div 
    class="search-container"
    :class="site"
  >
    <form 
      class="search"
      :class="site"
      @submit.prevent="submitQuery"
    >
      <div
        class="search-form"
        :class="[site, {
          focused: focused,
          'has-results': hasResults,
        }]"
      >
        <input
          type="text"
          class="search-input"
          :value="query"
          :placeholder="placeholderDynamic"
          :class="{ 'has-results': hasResults && focused }"
          @keydown.up.prevent="changeSelection(-1)"
          @keydown.down.prevent="changeSelection(1)"
          @keydown.right="useSelection()"
          @focus="onFocus"
          @blur="onBlur(false)"
          @keyup.esc="onBlur(true)"
          @input="(e) => onChange(e)"
          ref="originalInput"
        />

        <button
          class="search-button"
          type="submit"
          :class="{
            'has-results': hasResults && focused,
            bn: site === 'bn',
            fd: site === 'fd',
          }"
        >
          <i class="fa fa-fw fa-search fa-lg"></i>
        </button>
      </div>
    </form>

    <transition name="fade" mode="out-in">
      <autocomplete
        v-if="focused"
        :query="query"
        :results="results"
        :styled-results="styledResults"
        :selected-fitment="selectedFitment"
        :selected-fitment-text="selectedFitmentText"
        @mouseenter="mouseOverAutocomplete"
        @mouseleave="mouseLeaveAutocomplete"
        :query-loading="loading"
        :auth="auth"
        :cda="cda"
        :domain="domain"
        :addMin="addMin"
        :addMax="addMax"
        :holiday="holiday"
        :site="site"
        :search-items="searchItems"
        :popular-brands="popularBrands"
        :is-mobile="isMobile"
        :dc="dc"
				:product-pipeline="productPipeline"
				:typeahead-pipeline="typeaheadPipeline"
				:query-bearer-token="queryBearerToken"
      ></autocomplete>
    </transition>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from "axios";
import EventBus from "../../event-bus.js";
import Autocomplete from "./Autocomplete.vue";
import Data from '../../mixins/data.js';
// import Categories from "./Categories.vue";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export default {
  name: "search",
  components: { Autocomplete },
  mixins: [Data],
  props: {
    site: { type: String, default: "pz" },
    override: { type: Object, default: null },
    auth: { type: Number, default: 0 },
    cda: { type: Number, default: 0 },
    placeholder: {
      type: String,
      default: "Search Parts or Part Number(s)",
    },
    addMin: { type: String },
    addMax: { type: String },
    holiday: { type: Boolean },
    q: { type: String, default: "" },
    updating: { type: Boolean, default: false },
    apiSubdomain: { type: String, default: "wwww" },
    searchItems: { type: Array },
    popularBrands: { type: String },
    isMobile: { type: Boolean },
    dc: {},
	productPipeline: { type: String },
	typeaheadPipeline: { type: String },
	queryBearerToken: { type: String },
  },

  data() {
    return {
      query: "",
      originalQuery: "",
      focused: false,
      selection: null,
      hasSelection: false,
      toggledSelection: false,
      results: [],
      styledResults: [],
      category: 0,
      selectedFitment: "",
      selectedFitmentText: "",
      closeOnClick: true,
      loading: false,
      requestId: 0,
      prevRequestId: 0,
      setNewOverride: false,
    };
  },

  computed: {
    hasResults() {
      return this.query.length > 2;
    },
    placeholderDynamic() {
      if (this.selectedFitment.length > 0) {
        return "Search Parts for your " + this.selectedFitmentText;
      }

      return this.placeholder;
    },
    domain() {
      let root = "https://" + this.apiSubdomain + ".";

      switch (this.site) {
        case "pz":
          root += "partzilla.com";
          break;
        case "bn":
          root += "boats.net";
          break;
        case "fd":
          root += "firedog.com";
          break;
      }

      return root;
    },
    searchQuery() {
      let q = "";
      let term = "q=" + this.query.replace(/ /gm, "+").replace(/&/, "%26").replace(/-/g, "_");

      q += atob(this.typeaheadPipeline) + "?" + term;

      if (this.selectedFitment.length > 0) {
        q += this.selectedFitment;
      }
			
      return q;
    },
    popularBrandsDecoded() {
      return this.parseBase64(this.popularBrands);
    }
  },

  watch: {
    query(val) {
      if (val.length === 0) this.selection = null;
    },
    updating(val) {
      if (!val) return;

      this.setSelectedFitment();
    },
  },

  methods: {
    getResults: function () {
      if (this.query.length < 1) {
        this.results = [];
        this.loading = false;
        return;
      }
			// set loading state
      this.loading = true;
			
      axios
				.get(this.searchQuery, {
					cancelToken: source.token,
					headers: {
						"Authorization": "Bearer " + atob(this.queryBearerToken)
					},
				})
        .then((result) => {
          this.loading = false;
          // console.log(result);

          if (result.data.grouped) {
            this.results = result.data.grouped.type.groups;
          }

          if (result.data.highlighting) {
            this.styledResults = result.data.highlighting;
          }
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            console.log("Request canceled:", thrown.message);
          }

          this.loading = false;
        });
    },
    changeSelection(val) {
      if (this.focused) EventBus.$emit("ChangeSelection", val);
    },
    useSelection() {
      if (this.selection && this.selection.length >= 0) {
        this.query = this.selection;
      }
    },
    onFocus() {
      this.focused = true;
      this.$emit("focus", true);
    },
    onBlur(key = false) {
      if (this.closeOnClick || key) {
        this.focused = false;
        this.hasSelection = false;
        // this.selection = "";
        this.$emit("focus", false);
      }
    },
    onChange(e) {
      this.query = e.target.value;

      if (this.hasSelection) {
        const regex = new RegExp(this.selection.id, "g");

        if (regex.test(this.query)) {
          // this.hasSelection = false;
          this.toggledSelection = true;
        } else {
          this.toggledSelection = false;
          this.hasSelection = false;
          this.selection = "";
        }
      }

      if (this.query.length > 0) this.focused = true;

      if (!this.hasSelection) this.search();
    },
    setCategory(cat) {
      this.category = cat;
    },
    submitQuery() {
      this.$nextTick().then(() => {
        EventBus.$emit("AttemptSubmission", {
          doc: this.selection,
          toggled: this.toggledSelection,
          clicked: false,
        });
      });
    },
    clearSelected() {
      this.selectedFitment = "";
      this.selectedFitmentText = "";
    },
    mouseOverAutocomplete() {
      this.closeOnClick = false;
    },
    mouseLeaveAutocomplete() {
      this.closeOnClick = true;
    },
    setSelectedFitment() {
      let ride;
      let path = window.location.pathname.split("/");

      if (
        path.includes("catalog") &&
        localStorage.getItem(this.site + "-catalog-stored-ride")
      ) {
        ride = JSON.parse(
          localStorage.getItem(this.site + "-catalog-stored-ride")
        );
      } else if (localStorage.getItem(this.site + "-stored-ride")) {
        ride = JSON.parse(localStorage.getItem(this.site + "-stored-ride"));
      } else {
        this.clearSelected();
        return;
      }

      if (this.selectedFitmentText !== ride.name) {
        this.setNewOverride = true;
      }

    //   this.selectedFitment = "&fitment=" + ride.catalog.fitment;
      this.selectedFitmentText = ride.name;
    },
    buildSelectedQuery(ride) {
      let query = "";

      ride.queries.forEach((q) => {
        query += q;
      });

      return query;
    },
  },

  created() {
    this.search = debounce((g, s) => {
      return this.getResults(g, s);
    }, 100);
  },

  mounted() {
    EventBus.$on("update-fitment", () => {
      this.setSelectedFitment();
    });

    EventBus.$on("close-search", () => {
      this.focused = false;

      if (this.$refs.originalInput) {
        this.$refs.originalInput.blur();
      }
    });

    EventBus.$on("ride-cleared", () => {
      this.clearSelected();
    });

    EventBus.$on("UpdateSelection", (s) => {
      if (s.index > -1 && s.data.type === "Suggestion") {
        if (!this.hasSelection) {
          this.originalQuery = (" " + this.query).slice(1);
        }
        this.hasSelection = true;
        this.selection = s.data;
        this.query = this.selection.id.replace(/_/g, " ");
      } else {
        if (this.originalQuery) this.query = this.originalQuery;
        this.hasSelection = false;
        this.selection = null;
      }
    });

    if (this.q !== "") {
      this.query = this.q;
    }

    this.setSelectedFitment();
  },
};
</script>

<style lang="scss">
.mobile-header {
  .search-container {
    margin: 0 auto;
    padding: 0 1em 1em 1em;
    &.bn {
      margin: 0 auto;
    }
  }
}
div.search-container {
  width: 100%;
  &.bn {
    margin: 0 0 0 4rem;
  }

  .search {
    margin: 0;
  }

  div.search-form {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    display: flex;
    &.pz {
      border: 2px solid transparent;

      ::placeholder {
        color: #777;
      }
    }
    &.bn {
      border: 2px solid #ef6225;

      ::placeholder {
        color: #3e3e3e;
      }
    }
    &.focused {
      &.pz {
        border-color: #c3161c;
      }

      &.bn {
        border-color: #ef6225;
      }

      &.fd {
        border-color: #33ac00;
      }

      border-bottom-color: white !important;

      &.has-results {
        border-bottom-color: white;
      }
    }

    input.search-input {
      border: none;
      outline: 0;

      flex-basis: calc(100% - 64px);
      background: white;
      padding: 0.55em;

      color: black;

      font-size: 1em;

      border-radius: 0;
      -webkit-border-radius: 0;

      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;

      &:active,
      &:focus {
        border: none;
        outline: 0;
      }
    }

    button.search-button {
      border: none;
      outline: 0;

      flex-basis: 64px;
      background: white;
      color: black;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -webkit-border-bottom-right-radius: 5px;

      &:hover {
        background-color: #c3161c;
        color: white;
      }

      &.bn:hover {
        background-color: #ef6225;
      }

      &.fd:hover {
        background-color: #33ac00;
      }
    }
  }
}

.search-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1em;

  a:hover {
    cursor: pointer;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.hidden {
  opacity: 0;
}

.shown {
  opacity: 1;
}

@media (max-width: 768px) {
  div.search-container {
    // padding: 8px;

    div.search-form {
      input.search-input {
        padding: 8px;
      }
    }
  }
}
</style>
