<template>
	<div class="menu-list">
		<template v-for="(item, index) in filteredItems">
			<ul
				v-if="Object.entries(item).length > 1"
				class="list-items"
				:key="index"
				ref="menu"
			>
				<li 
					v-if="count >= 1" 
					class="list-buttons flexer"
				>
					<button
						class="btn btn-link back-btn"
						@click.prevent="revertSubMenu(item.title)"
					>
						<i class="fa fa-w fa-arrow-left"></i>&nbsp;Back
					</button>
					<button
						class="btn btn-link start-over-btn"
						@click.prevent="restartMenu()"
					>
						<i class="fa fa-w fa-undo"></i>&nbsp;Restart
					</button>
				</li>
				<li
					v-if="item.title"
					:key="filteredItems.indexOf(item)"
					class="list-title"
				>
					<span>
						{{ item.title }}
					</span>
				</li>
				<li
					v-for="(entry, key) in item.items"
					:key="key + '_list_item'"
					class="list-item"
					:class="{ 'list-title': entry.bold }"
				>
					<div
						class="list-separator"
						v-if="entry.bold && entry !== item.items[0]"
					></div>
					<a
						v-if="entry.url"
						:href="entry.url"
					>
						{{ entry.label }}
					</a>
					<span v-else-if="entry.bold">
						{{ entry.label }}
					</span>
					<button
						v-else
						class="btn-link"
						@click.stop="showSubMenu(item.items, key)"
					>
						{{ entry.label }}
					</button>
				</li>
				<div v-if="index <= 1 && count < 1" class="list-separator"></div>
			</ul>
		</template>
	</div>
</template>

<script>
export default {
	name: 'menu-list',
	props: ['items', 'title'],
	data() {
		return {
			filteredItems: this.items,
			count: 0,
		};
	},
	methods: {
		/**
		 * This function is used to look for the matching title & update the 
		 * filteredItems variable with the parent array of the matching title.
		 * @param {String} title 
		 */
		revertSubMenu(title) {
			this.count--;
			// Since js/vue gets mad about too much recursion, we unfortunately must use nested for loops.
			for (let iterator = 0; iterator < this.items.length; iterator++) {
				const entry = this.items[iterator];
				for (const key in entry.items) {
					if (Object.hasOwnProperty.call(entry.items, key)) {
						const element = entry.items[key];
						if (element.title == title) {
							this.filteredItems = this.items;
							this.$emit('showingSubMenu', false);
							break;
						}
						for (const item in element.items) {
							if (Object.hasOwnProperty.call(element.items, item)) {
								const found = element.items[item];
								if (found.title == title) {
									this.filteredItems = [element];
									break;
								}
								for (const index in found.items) {
									if (Object.hasOwnProperty.call(found.items, index)) {
										const item = found.items[index];
										if (item.title == title) {
											this.filteredItems = [found];
											break;
										}
									}
								}
							}
						}
					}
				}
			}
		},
		/**
		 * This function is used to drill down into the next set of items to show the submenu of that array.
		 * @param {Array<Object>} items 
		 * @param {Number} index 
		 */
		showSubMenu(items, index) {
			this.$emit('showingSubMenu', true);
			this.filteredItems = Object.entries(items)[index];
			this.count++;
			// wait for the next DOM update cycle
			this.$nextTick(() => {
				// then scroll to the top of the menu element
				this.$refs.menu[0].firstElementChild.scrollIntoView({
					block: "start",
					behavior: "smooth"
				});
			});
		},
		/**
		 * This function is used to revert the menu back to it's original state with one click of a button.
		 */
		restartMenu() {
			this.filteredItems = this.items;
			this.count = 0;
			this.$emit('showingSubMenu', false);
			// wait for the next DOM update cycle
			this.$nextTick(() => {
				// then scroll to the top of the first menu element's offset parent: aside.main-menu
				// this is necessary for mobile mainly to show the garage & location buttons to the user at the top
				// otherwise it would scroll just under those elements
				const mainMenu = this.$refs.menu.find((menu) => menu.firstElementChild.innerText.toLowerCase() == 'shop oem parts').offsetParent;
				mainMenu.scrollIntoView({
					block: "start",
					behavior: "smooth"
				});
			});
		}
	},
};
</script>
