<template>
  <div class="fitment-container" :class="{ full: partFinder }">
    <div class="fitment-bar">
      <builder :builder="builder" :name-from="nameFrom" :machineToken="machineToken" :axiosBaseUrl="axiosBaseUrl"
        :error="error" @selected="selectedFitment" />
    </div>

    <div class="status-bar">
      <div v-if="hasStatus" :class="statusTextClasses">
        <i class="fa fa-fw fa-lg" :class="statusIconClasses"></i> {{ statusText }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";
import data from "../../mixins/data.js";
import garage from "../../mixins/garage.js";
import EventBus from "../../event-bus.js";
import Builder from "./Builder.vue";

export default {
  name: "fitment-bar",
  mixins: [data, garage],
  components: { Builder },

  props: {
    site: { type: String, default: "pz" },
    uri: {
      type: String,
      //   default: "/garage/api/list",
    },
    partFinder: { type: Boolean, default: false },
    multiPart: { type: Boolean, default: false },
    brand: { type: String, default: "" },
    category: { type: String, default: "PARTS" },
    override: { type: String, default: null },
    catalog: { type: Boolean, default: false },
    loadingText: { type: String, default: "LOADING..." },
    searchingText: { type: String, default: "REVVING UP..." },
    facetType: { type: String, default: "vehicle" },
    nameFrom: {
      type: Array,
      default: () => ["Year", "Make", "Base Model", "Model"],
    },
    builder: {
      type: Array,
      default: () => ["Make", "Year", "Type", "Model"],
    },
    query: { type: String, default: "" },
    searching: { type: Boolean, default: false },
    searchFacets: {
      type: String,
      default:
        "&vehicle_make=kawasaki&vehicle_type=atv&vehicle_year=2017&vehicle_model=kfx50",
    },
    machineToken: { type: String },
    axiosBaseUrl: { type: String },
    isAuthenticated: { type: Boolean, default: false },
    clearing: { type: Boolean, default: false },
    updating: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: true,
      fitmentValid: false,
      storedRide: null,
      hasStoredRide: false,
      localGarage: [],
      apiParams: "&api=1",
      addingToGarage: false,
      addedToGarage: false,
      error: null
    };
  },

  computed: {
    overrideDecoded() {
      if (this.override == null) return {};

      return this.parseBase64(this.override);
    },
    queryParams() {
      const search = window.location.search;
      const params = new URLSearchParams(search);

      // console.log(params.get("q"));

      if (params.has("q")) {
        return "?q=" + params.get("q").replace(/ /g, "+");
      }

      if (this.query === "") return this.query + "?q=*:*";

      return "?q=" + this.query.replace(/ /g, "+");
    },
    searchEndpoint() {
      if (window.location.hostname.includes("localhost")) {
        if (this.site === "pz")
          return (
            // "https://www.partzilla.com" +
            // 'https://staging-machine-api.outdoornetwork.com/' +
            this.axiosBaseUrl +
            this.uri +
            this.queryParams +
            this.apiParams
          );
        if (this.site === "bn")
          return (
            "https://www.boats.net" +
            this.uri +
            this.queryParams +
            this.apiParams
          );
      }

      let endpoint = this.uri + "?q=parts" + this.apiParams;

      if (this.partFinder) endpoint += "&allow_cache=0";
      else endpoint += "&allow_cache=1";

      return endpoint;
    },
    vehicle() {
      return this.site === "pz" ? "RIDE" : "BOAT";
    },
    brandUpper() {
      return this.brand.toUpperCase();
    },
    findYourTemplate() {
      let temp =
        `FIND ` +
        this.brandUpper +
        " " +
        this.category.toUpperCase() +
        `<br/><strong>FOR YOUR ` +
        `</strong>`;

      if (!this.hasStoredRide) {
        temp += " " + this.vehicle;
      }

      return temp;
    },
    storageName() {
      if (this.partFinder) {
        return this.site + "-" + this.brand + "-finder";
      }

      if (this.catalog) {
        return this.site + "-" + "catalog-stored-ride";
      }

      return this.site + "-stored-ride";
    },
    hasStatus() {
      if (this.addedToGarage || this.addingToGarage || this.clearing || this.updating) {
        return true;
      }
      return false;
    },
    statusText() {
      if (this.addedToGarage) return 'Added to Garage!';
      if (this.addingToGarage) return 'Adding to Garage!';
      if (this.clearing) return 'Clearing the Garage!';
      if (this.updating) return 'Updating the Garage!';
    },
    statusTextClasses() {
      if (this.addedToGarage) return 'text-success';
    },
    statusIconClasses() {
      if (this.addedToGarage) return 'fa-check';

      return 'fa-spinner fa-spin';
    }
  },

  methods: {
    /**
     * This function updates the back-end, 
     * which updates the cookies on the front-end for the user.
     * In order to append the garage_id data(from the `garage/add` route request) 
     * to the new ride data, a new mutatable object is needed.
     * 
     * @param {*} ride 
     */
    selectedFitment(ride) {
      const rideData = {
        ...ride,
      };
      this.updateStoredRide(rideData);

      this.addToGarage(rideData)
        .then((results) => {
          rideData.garage_id = results.data;
          this.updateStoredRide(rideData);

          this.addingToGarage = false;
          this.addedToGarage = true;

          this.addToLocalGarage();

          this.$emit("selected");
          EventBus.$emit("init");
        })
        .catch((results) => {
          if (results.response.status === 401) {
            // window.location.reload();
            ride.garage_id = null;
            this.updateStoredRide(ride);

            this.addingToGarage = false;
          }
        });
    },
    findFromStoredRide() {
      let query;

      if (this.partFinder) query = this.searchEndpoint;
      else query = "/search" + this.queryParams;

      // console.log(query);

      this.storedRide.queries.forEach((q) => {
        query += q.replace(/\\\+/g, "+");
      });

      if (this.partFinder) {
        this.findPart(query);
        return;
      }

      window.location.assign(query);
    },
    findPart(query) {
      axios.get(query + this.apiParams).then((result) => {
        let data;

        if (!this.multiPart) {
          data = result.data.data.docs.products[0].product.price_file.json;
        } else {
          data = result.data.data.docs.products;
        }

        this.$emit("found", data);
        this.fitmentValid = false;
      });
    },
    updateStoredRide(ride) {
      this.setStoredRide(ride);
      this.$emit('fitment-changed');
    },
    addToGarage(ride) {
      this.addedToGarage = false;
      this.addingToGarage = true;
      const test = window.location.hostname.includes("localhost")
        ? "localhost"
        : "";

      return axios.get(
        test +
        "/garage/add/" +
        ride.id +
        "?api=1"
      ).catch((error) => {
        console.log(error)
        this.addingToGarage = false
        const { status } = error.response
        //Server error
        if (status >= 500 && status < 600) {
          this.error = "Sorry, there was an unexpected problem looking up your model."
        } else {
          this.error = error
        }
      });
    },
    addToLocalGarage() {
      let garage = [];

      if (localStorage.getItem(this.site + "-local-garage") !== null) {
        garage = JSON.parse(localStorage.getItem(this.site + "-local-garage"));
      }

      garage.push(this.storedRide);

      localStorage.setItem(this.site + "-local-garage", JSON.stringify(garage));
    },
  },

  mounted() {
    EventBus.$emit("init");
    return;

    // if (Object.keys(this.overrideDecoded).length > 0) {
    //   EventBus.$emit("init");
    //   return;
    // }

    // this.checkForStoredRide();

    // if (localStorage.getItem(this.site + "-local-garage") !== null) {
    //   this.localGarage = JSON.parse(
    //     localStorage.getItem(this.site + "-local-garage")
    //   );
    // }

    // if (this.hasStoredRide && !this.searching) {
    //   this.getStoredRide();

    //   this.loading = false;

    //   if (this.partFinder) this.findFromStoredRide();
    // } else {
    //   EventBus.$emit("init");
    // }
  },
};
</script>

<style lang="scss">
section#fitment {
  background-color: white;
  border-bottom: 1px solid #c1c1c1;
}

div.fitment-container.full {
  max-width: 1920px;
  background-color: white;
}

div.fitment-bar {
  padding: 1em;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: black;

  width: 100%;

  h4.loading-text {
    color: black !important;
  }
}

button.btn.btn-garage {
  background-color: transparent;

  &:active,
  &.active,
  &:focus {
    box-shadow: none;
    background-color: #eee;
    color: black !important;
  }
}

ul.garage-dropdown {
  max-height: 200px;
  overflow-y: scroll;
}

.btn-primary.btn-darker {
  background-color: #181818 !important;
  border-color: #181818 !important;

  &:hover {
    background-color: #666 !important;
  }
}

div.status-bar {
  padding: 1em;
  font-size: 1.8rem;
  text-align: center;
}

@media (max-width: 768px) {
  div.fitment-bar {
    flex-direction: column;

    div.selection {
      margin-left: 0;
      flex-direction: column;

      div.parameter {
        flex-direction: column;
        flex-basis: unset;
        margin: 4px 0;
        padding: 0;
        width: 100%;

        &:not(:last-child) {
          border-right: none;
        }
      }
    }
  }

  div.loading {
    margin-left: 0;
  }
}

@media (max-width: 420px) {
  div.fitment-bar {
    div.stored-ride {
      flex-direction: column;
      align-items: center;
      padding: 0 12px;
    }
  }
}
</style>
