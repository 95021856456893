<template>
  <div>
    <button class="menu-button" @click="$emit('toggle-menu')" :class="[site, {stuck: stuck}]">
      <i
        class="fa fa-fw fa-2x"
        :class="{ 'fa-bars': !open, 'fa-times': open }"
      ></i>
      <span>{{ label }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "menu-button",

  props: {
    open: { type: Boolean, default: false },
    site: { type: String },
    stuck: { type: Boolean },
  },

  computed: {
    label() {
      if (this.open) {
        return "CLOSE";
      }

      return "MENU";
    },
  },
};
</script>

<style lang="scss">
button.menu-button {
  align-items: center;
  border-radius: 3px;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin: 0 1rem;
  outline: none;
  transition: all 0.3s ease-in-out;
  &.bn {
    color: #fff;
    &:hover {
      background-color: transparent;
      color: darken(#fff, 15%);
    }
    @media screen and (max-width: 991px) {
      &:hover {
        background-color: transparent;
        color: darken(#3777bc, 15%);
      }
    }
    &.stuck {
      color: #3777bc;
      &:hover {
        color: darken(#3777bc, 15%);
        background-color: transparent;
      }
    }
  }
  &.pz {
    color: #fff;
    &:hover {
      color: #c3161c;
    }
  }
  &.fd {
	color: #fff;
	&:hover {
      background-color: transparent;
      color: darken(#fff, 15%);
	}
  }
}

@media (max-width: 991px) {
  button.menu-button {
    margin: 0;
    &.bn {
      color: #3777bc;
    }
  }
}
</style>