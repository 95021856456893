<template>
	<div class="selection">
		<div class="parameters">
			<div class="parameter" v-for="(visibleFacet, index) in visibleFacets" :key="visibleFacet.name">
				<div>
					<label :for="visibleFacet.name.toLowerCase() + '-select'">
						{{ visibleFacet.name.toUpperCase() }}
						<i v-if="showLoadingSpinner(visibleFacet, index)" aria-hidden="true"
							class="fa fa-fw fa-spinner fa-spin"></i>
						<transition-group name="fade">
							<i v-if="visibleFacet.skipped || visibleFacet.valid" key="check" aria-hidden="true"
								class="fa fa-fw fa-check text-success"></i>
							<i v-if="visibleFacet.skipped" key="info" aria-hidden="true" class="fa fa-fw fa-info-circle"
								title="This selection has been auto-filled due to a limited number of selections."></i>
						</transition-group>
					</label>

					<div class="dropdown-container">
						<select :name="visibleFacet.name.toLowerCase() + '-select'"
							:id="visibleFacet.name.toLowerCase() + '-select'"
							:disabled="visibleFacet.data.length === 0 || visibleFacet.valid"
							v-model="usersSelections[index]" @change="updateFacet(visibleFacet)">
							<option disabled :value="null">
								Select
							</option>
							<option v-for="option in visibleFacet.data" :key="option.name" :value="option.name">
								{{ option.name ? option.name : option.value }}
							</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div v-show="showErrorMessage">
			<p class="errorText">{{ errorMessage }}</p>
		</div>
		<button class="clear-button btn btn-xs btn-default xs:btn-grow" @click="clearAll(0)"
			v-if="validFacets.length > 0">
			Clear All
		</button>
	</div>
</template>

<script>
import axios from 'axios';
import Strings from '../../mixins/strings.js';
import Garage from '../../mixins/garage.js';

export default {
	name: 'builder',
	mixins: [Strings, Garage],

	props: {
		nameFrom: { default: () => ['Year', 'Make', 'Base Model', 'Model'] },
		builder: { type: Array },
		machineToken: { type: String },
		axiosBaseUrl: { type: String },
		error: { type: String }
	},

	data() {

		return {
			facets: [],
			loading: false,
			allFourSelected: false,
			usersSelections: [null, null, null, null],
			yearsBank: [],
			makesBank: [],
			tagsBank: [],
			modelsBank: [],
			showErrorMessage: false,
			errorMessage: '',
		};
	},
	watch: {
		error: {
			handler(newValue, oldValue) {
				if (newValue != oldValue && newValue != null) {
					this.alertUserOfFailure(newValue);
				}
			},
			immediate: true
		}
	},
	computed: {
		visibleFacets() {
			return this.facets.filter((f) => f.visible);
		},
		validFacets() {
			return this.facets.filter((f) => f.valid);
		},
		machineTokenDecoded() {
			return atob(this.machineToken);
		},
		machineApiAxiosInstance() {
			const base = 'https://' + this.axiosBaseUrl;
			const instance = axios.create({
				baseURL: base,
				headers: { Authorization: 'Bearer ' + this.machineTokenDecoded },
			});

			return instance;
		},

	},
	methods: {
		init() {
			this.facets = [];
			this.built = [];
			this.usersSelections = [null, null, null, null];
			this.buildDropdowns();
			this.getMakeSelections();
		},
		/**
		 * This function makes a request & returns a response from the machine api's related search route.
		 *
		 * @param {String} route - singular - The name of the route the request will make to the machine api
		 * @param {Number} page - The page number used as a search parameter
		 * @return Response
		 */
		async getSelections(facet = 'make', filters = []) {
			facet = facet.toLowerCase();
			let query = '*:*';
			const aggregationName = facet + 's';
			const facetJSON = {
				[aggregationName]: {
					type: 'terms',
					field: facet == 'type' ? 'types' : facet,
					minCount: 1,
					limit: -1,
					sort: `index ${facet === 'year' ? 'desc' : 'asc'}`,
				},
			};
			if (filters.length !== 0) {
				query = filters.map((filter) => `+${filter.name}:"${filter.value}"`).join(' ');
			}

			return await this.machineApiAxiosInstance({
				params: {
					q: query,
					'json.facet': JSON.stringify(facetJSON),
				},
			});
		},
		/**
		 * This function returns the years from the machine api's year search route.
		 *
		 * @return Response
		 */
		getYearSelections() {
			this.loading = true;
			const selectionType = 'Year';
			const dataBank = this.facets.find((facet) => facet.name === selectionType).data;
			const filters = [
				{
					name: 'make',
					value: this.usersSelections[0],
				},
			];

			this.getSelections(selectionType, filters)
				.then((response) => {
					this.loading = false;
					this.yearsBank =
						response.data.facets.years.buckets.length > 0
							? response.data.facets.years.buckets.map((el) => {
								return { name: el.val };
							})
							: [];
				})
				.then(() => {
					dataBank.push(...this.yearsBank);
				})
				.catch((error) => this.alertUserOfFailure(error))
				.finally(() => {
					// create a fallback for if there happens to be no data available for the user to select from.
					if (dataBank.length === 0) {
						this.skipSelection(selectionType);
					}
				});
		},
		/**
		 * This function returns the makes from the machine api's make search route.
		 *
		 * @return Response
		 */
		getMakeSelections() {
			this.loading = true;
			const selectionType = 'Make';
			const dataBank = this.facets.find((facet) => facet.name === selectionType).data;
			this.getSelections(selectionType, [])
				.then((response) => {
					this.loading = false;

					//filter out counts, wrap in object
					this.makesBank =
						response.data.facets.makes.buckets.length > 0
							? response.data.facets.makes.buckets.map((el) => {
								return { name: el.val };
							})
							: [];
				})
				.then(() => {
					dataBank.push(...this.makesBank);
				})
				.catch((error) => {
					this.alertUserOfFailure(error)
				})
				.finally(() => {
					// create a fallback for if there happens to be no data available for the user to select from.
					if (dataBank.length === 0) {
						this.skipSelection(selectionType);
					}
				});
		},
		/**
		 * This function returns the tags from the machine api's machine search route.
		 *
		 * @return Response
		 */
		getTagSelections() {
			this.loading = true;
			const selectionType = 'Type';
			const dataBank = this.facets.find((facet) => facet.name === selectionType).data;
			const filters = [
				{
					name: 'make',
					value: this.usersSelections[0],
				},
				{
					name: 'year',
					value: this.usersSelections[1],
				},
			];

			this.getSelections(selectionType, filters)
				.then((response) => {
					this.loading = false;
					this.tagsBank =
						response.data.facets.types.buckets.length > 0
							? response.data.facets.types.buckets.map((el) => {
								return { name: el.val };
							})
							: [];
				})
				.then(() => {
					dataBank.push(...this.tagsBank);
				})
				.catch((error) => this.alertUserOfFailure(error))
				.finally(() => {
					// create a fallback for if there happens to be no data available for the user to select from.
					if (dataBank.length === 0) {
						this.skipSelection(selectionType);
					}
				});
		},
		/**
		 * This function returns the models from the machine api's machines search route.
		 *
		 * @return Response
		 */
		getModelSelections() {
			this.loading = true;
			const selectionType = 'Model';
			const dataBank = this.facets.find((facet) => facet.name === selectionType).data;
			const filters = [
				{
					name: 'make',
					value: this.usersSelections[0],
				},
				{
					name: 'year',
					value: this.usersSelections[1],
				},
				{
					name: 'types',
					value: this.usersSelections[2],
				},
			];
			this.getSelections(selectionType, filters)
				.then((response) => {
					this.loading = false;
					this.modelsBank = response.data.facets.models.buckets.map((el) => ({ name: el.val }));
				})
				.then(() => {
					dataBank.push(...this.modelsBank);
				})
				.catch((error) => this.alertUserOfFailure(error))
				.finally(() => {
					// create a fallback for if there happens to be no data available for the user to select from.
					if (dataBank.length === 0) {
						// this.skipSelection(selectionType);
						this.alertUserOfFailure(
							"We're sorry - we don't have parts for any models with this combination. Please try again."
						);
					}
				});
		},
		/**
		 * This function is run when the length of valid facets matches the length of visible facets.
		 * It returns a response after getting the latest data with the
		 * year, make, model & type selected by the user, to update the garage.
		 *
		 * @returns Response
		 */
		selected() {
			const filters = [
				{
					name: 'make',
					value: this.usersSelections[0],
				},
				{
					name: 'year',
					value: this.usersSelections[1],
				},
				{
					name: 'types',
					value: this.usersSelections[2],
				},
				{
					name: 'model',
					value: this.usersSelections[3],
				},
			];
			this.getSelections('id', filters)
				.then((results) => {
					// this.$emit("load", false);

					let ride = results.data.response.docs[0];
					if (ride !== null) {
						ride.name = [];
						ride.short_name = [];
						const { make, year, model, submodel, ...rest } = ride;
						if (make && make !== null) {
							ride.name.push(make);
							ride.short_name.push(make);
						}
						if (year && year !== null) {
							ride.name.push(year);
						}
						if (model && model !== null) {
							ride.name.push(model);
							ride.short_name.push(model);
						}
						if (submodel && submodel !== null) {
							ride.name.push(submodel);
							ride.short_name.push(submodel);
						}
						ride.name = ride.name.join(' ');
						ride.short_name = ride.short_name.join(' ');

						//use first from doc_keys & and selected type
						ride.doc_key = ride.doc_keys && ride.doc_keys.length > 0 ? ride.doc_keys[0] : null;
						ride.type = ride.types && ride.types.length > 0 ? ride.types[0] : null;

						this.$emit('selected', ride);
					} else {
						throw new Error('Sorry, we could not find that ride.');
					}
				})
				.catch((error) => this.alertUserOfFailure(error));
		},
		/**
		 * This function is run every time the user makes a selection.
		 * Depending on the name of the facet that the user is selecting from,
		 * the function will use requests to get new data for the prev/next selections.
		 *
		 * @param {*} facet
		 */
		updateFacet(facet = null) {
			if (facet !== null) {
				facet.valid = true;
			}
			if (this.validFacets.length === this.visibleFacets.length) {
				this.allFourSelected = true;
				this.selected();
				return;
			}
			switch (facet.name) {
				case 'Year':
					this.getTagSelections();
					break;
				case 'Make':
					this.getYearSelections();
					break;
				case 'Type':
					this.getModelSelections();
					break;
			}
		},
		/**
		 * Since there is no selections for the user to make, manually update it here.
		 *
		 * @param {*} selection
		 */
		skipSelection(selection) {
			this.updateFacet({
				name: selection,
				visible: true,
				valid: false,
				value: null,
				data: [{}],
			});
			// instead of creating watchers, needed to set the values
			// alert the other functions this type is now valid
			this.facets.find((facet) => facet.name === selection).valid = true;
			// alert the user of it being skipped
			this.facets.find((facet) => facet.name === selection).skipped = true;
		},
		/**
		 * This function is used to loop through the builder types & then push the data to the facets.
		 *
		 * @returns {Array} `facets`
		 */
		buildDropdowns() {
			for (let i = 0; i < this.builder.length; i++) {
				const name = this.builder[i]; // either make, model, year or type
				const data = []; // empty array for storing the default makes & models, then getting updated after each selection

				this.facets.push({
					name: name,
					visible: true,
					valid: false,
					value: null,
					data: data,
				});
			}
		},
		/**
		 * This function clears the dropdowns so the user can start over.
		 *
		 * @param {*} index
		 */
		clearAll(index) {
			if (index === 0) {
				this.loading = false;
				this.facets = [];
				this.built = [];
				this.makesBank = [];
				this.yearsBank = [];
				this.tagsBank = [];
				this.modelsBank = [];
				this.showErrorMessage = false;
				this.errorMessage = '';
				this.usersSelections = [null, null, null, null];
				this.buildDropdowns();
				this.getMakeSelections();
				return;
			}

			this.showErrorMessage = false;
			this.errorMessage = '';

			this.facets[index].value = null;
			this.facets[index].valid = false;

			if (index < this.facets.length - 1) {
				this.facets[index + 1].data = [];
				this.facets[index + 1].valid = false;
			}
			this.init();
		},
		/**
		 * This function gathers names & builds a list of facets for the ride.
		 */
		selectFitmentFallback() {
			let name;
			let values = [];
			let queries = [];

			name = this.buildName();

			this.validFacets.forEach((f) => {
				if (f.value !== null) {
					values.push({ [f.name]: f.value.value });
					queries.push(f.value.query.replace(/\\\+/g, '+'));
				}
			});

			let ride = {
				name: name,
				values: values,
				queries: queries,
			};

			// this.$emit("selected", ride);
		},
		/**
		 * This function loops through a prop of names, compares them with the valid facets.
		 */
		buildName() {
			let names = [];
			let name = '';
			let hasBase;

			for (let k = 0; k < this.nameFrom.length; k++) {
				let found = false;

				if (this.nameFrom[k] === 'Model' && hasBase) continue;

				this.validFacets.filter((b) => {
					if (b.name === this.nameFrom[k]) {
						if (b.name === 'Base Model' && b.value !== null) hasBase = true;

						found = true;

						if (b.value !== null) names.push(b.value.value);
						return false;
					} else {
						return true;
					}
				});
			}

			names.forEach((n, index) => {
				name += n;

				if (index < names.length - 1) name += ' ';
			});

			return name;
		},
		/**
		 * Update the error message for the user.
		 *
		 * @param {*} error
		 */
		alertUserOfFailure(error) {
			this.showErrorMessage = !this.showErrorMessage;
			if (error.message) {
				return (this.errorMessage = error.message);
			}
			if (error.request) {
				return (this.errorMessage = 'There was an error with your request. Please wait a moment & try again.');
			}
			if (error.response) {
				return (this.errorMessage = "We're having difficulties gathering that information.");
			} else {
				return (this.errorMessage = error);
			}
		},
		/**
		 * This functions is used to display the loading spinner to the user.
		 *
		 * @param {*} facet
		 * @param {*} index
		 */
		showLoadingSpinner(facet, index) {
			if (this.facets.length === 0) return false;

			if (this.loading && !facet.valid) {
				if (index === 0) return true;
				if (this.facets[index - 1] !== null && this.facets[index - 1].data.length > 0) return true;
			}

			return false;
		},
	},

	mounted() {
		this.init();
	},
};
</script>

<style lang="scss">
.selection {
	display: flex;
	flex-direction: column;
	width: 100%;

	.parameters {
		align-items: center;
		display: flex;
		gap: 0 1rem;
		width: 100%;

		> :not([hidden])~ :not([hidden]) {
			border: 0 solid #e3e3e3;
			border-right-width: 0;
			border-left-width: calc(1px * calc(1 - 0));
			padding: 0 0 0 1rem;
		}

		.parameter {
			display: flex;
			flex-basis: 25%;
			flex-direction: column;
			position: relative;

			label {
				align-items: center;
				color: #666;
				display: flex;
				font-size: 1.5rem;
				gap: 0.5rem;
				justify-content: flex-start;
				margin-bottom: 0;
			}

			.dropdown-container {
				position: relative;
				display: block;

				select {
					align-items: center;
					display: flex;
					flex: 1 1 auto;
					justify-content: center;
					max-width: 100%;
					width: 100%;

					&:disabled {
						color: #b8b8b8;
						background-color: #f8f8f8;
					}
				}
			}
		}
	}

	.clear-button {
		margin: calc(min(1.5vh, 24px) / 1.12) 0 0 auto;
	}

	.clear-selection-button {
		margin: 0 0 0 auto;
		align-items: center;
		background-color: transparent;
		border: none;
		border-radius: 9999px;
		display: flex;
		height: 24px;
		justify-content: center;
		outline: 0;
		transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		width: 24px;

		&:hover {
			background: rgb(240, 240, 240 / 0.5);
		}
	}
}

.errorText {
	background-color: rgba(red, 0.07);
	color: red;
	font-size: 1.25rem;
	font-weight: 600;
	margin: 1rem 0 0 0;
	padding: 1.25rem 0.5rem;
}

@media screen and (max-width: 414px) {
	.selection {
		.parameters {
			flex-wrap: wrap;
			gap: 1rem 0;

			> :not([hidden])~ :not([hidden]) {
				border: 0;
				padding: 0;
			}

			.parameter {

				&:first-child,
				&:last-child {
					padding: 0 !important;
				}

				select {
					margin: 0 !important;
				}
			}
		}

		.clear-button {
			width: 100%;

			&.xs\:btn-grow {
				height: 45px;
				border-radius: 4px;
				padding: 10px 15px;
				font-size: 16px;
			}
		}
	}

	.errorText {
		margin: 1rem auto;
		width: 100%;
	}
}
</style>
