var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-shade",class:{ open: _vm.open }},[_c('div',{staticClass:"outside",class:{ open: _vm.open },on:{"click":_vm.closeMenu}}),_c('aside',{staticClass:"main-menu",class:{ open: _vm.open }},[_c('div',{staticClass:"menu-container",class:{
				short: _vm.shortScreen,
				stuck: _vm.stuck,
				bn: _vm.site === 'bn',
				fd: _vm.site === 'fd',
			}},[((_vm.isMobile || _vm.stuck) && !_vm.showingSubMenu)?_c('div',{staticClass:"utils"},[(_vm.garageEnabled)?_c('garage-menu-button',{staticClass:"hidden-md hidden-lg",attrs:{"site":_vm.siteTag,"siteTag":_vm.siteTag},on:{"toggle-garage":function($event){return _vm.$emit('toggle-garage')},"menu-hovered":function($event){return _vm.$emit('menu-hovered')}}}):_vm._e()],1):_vm._e(),(_vm.hasStoredRide && !_vm.showingSubMenu)?_c('div',{staticClass:"shop-oem"},[_c('button',{staticClass:"btn btn-default btn-block btn-sm",on:{"click":_vm.goToComponent}},[_vm._v(" Shop Sub-Models ")])]):_vm._e(),(_vm.topItems.length > 0)?_c('menu-list',{attrs:{"items":_vm.topItems},on:{"showingSubMenu":_vm.updateShowingSubMenu}}):_vm._e(),(_vm.rideItems.length > 0 && _vm.hasStoredRide)?_c('menu-list',{attrs:{"items":_vm.rideItems},on:{"showingSubMenu":_vm.updateShowingSubMenu}}):_vm._e(),_c('menu-list',{attrs:{"items":_vm.items},on:{"showingSubMenu":_vm.updateShowingSubMenu}}),(_vm.isAuthenticated && !_vm.showingSubMenu)?_c('div',{staticClass:"menu-list"},[_c('div',{staticClass:"list-title"},[_vm._v("Account")]),_vm._l((_vm.accountLinks),function(item,index){return _c('div',{key:index + '_' + item.label,staticClass:"list-items"},[_c('a',{attrs:{"href":item.url},domProps:{"innerHTML":_vm._s(item.label)}})])}),_c('div',{staticClass:"list-separator"})],2):_vm._e(),(!_vm.showingSubMenu)?_c('div',{staticClass:"menu-list"},[_c('div',{staticClass:"list-title"},[_vm._v("Questions?")]),_vm._l((_vm.siteLinks),function(item,index){return _c('div',{key:index + '_' + item.label,staticClass:"list-items"},[_c('a',{attrs:{"href":item.url},domProps:{"innerHTML":_vm._s(item.label)}})])}),(!_vm.isAuthenticated)?_c('div',{staticClass:"list-separator"}):_vm._e()],2):_vm._e(),(!_vm.isAuthenticated && !_vm.showingSubMenu)?_c('div',{staticClass:"menu-list"},[_c('div',{staticClass:"list-title"},[_vm._v("Account")]),_vm._m(0),_vm._m(1)]):_vm._e(),_c('div',{staticStyle:{"height":"80px"}},[_vm._v(" ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-items"},[_c('a',{attrs:{"href":"/account/login"}},[_vm._v("Sign In")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-items"},[_c('a',{attrs:{"href":"/account/register"}},[_vm._v("Sign Up")])])
}]

export { render, staticRenderFns }