<template>
  <div>
    <!-- MODAL -->
    <transition name="modal" mode="out-in">
      <div class="garage-popup" v-if="open">
        <div class="menu-shade" :class="{ open: open }" @click="close"></div>
        <div class="popup-body">
          <!-- TOP -->
          <div class="popup-top">
            <button @click="close">
              <i class="fa fa-fw fa-times fa-lg"></i>
            </button>
          </div>

          <!-- CONTENT -->
          <div class="popup-content">
            <h3>Add a New {{ vehicleType }} to Your Garage</h3>
            <p>Select your specific vehicle to help find parts that fit.</p>
            <fitment-bar 
				:site="site"
				:machine-token="machineToken"
				:axiosBaseUrl="axiosBaseUrl"
				:isAuthenticated="isAuthenticated"
				:clearing="clearing"
				:updating="updating"
				@selected="selectedFitment" 
			/>
          </div>

          <!-- GARAGE -->
          <div class="garage" v-if="(hasStoredRide || localGarage.length > 0)">
            <div class="current" v-if="hasStoredRide">
              <h4>Currently Shopping For:</h4>
              <button class="garage-item" @click="shopForCurrent">
                <div class="item-name-container">
                  <span class="item-name">
                    <i class="fa fa-fw fa-check text-success fa-lg"></i>
					{{ storedRideName }}
                  </span>
                </div>
              </button>

              <div class="options">
                <button class="btn btn-default btn-xs" @click="shopForCurrent">
					<i class="fa fa-fw fa-wrench"></i> Shop OE Parts
				</button>
                <button class="btn btn-default btn-xs" @click="clearRide">
					<i class="fa fa-fw fa-times-circle"></i> Shop Without
                  	{{ capWords(vehicleType) }}
				</button>
              </div>
            </div>

            <div class="garage-list">
              <h4 v-if="localGarage.length > 0">{{ garageLabel }}</h4>

              <div class="garage-items" v-if="localGarage.length > 0">
                <button
                  class="garage-item"
                  v-for="(item, index) in localGarage"
                  :key="index"
                >
                  <div class="item-name-container" @click="setRide(item)">
                    <span class="item-name">{{ item.name ? item.name : `${item.year} ${item.make} ${item.model}` }}</span>
                  </div>
                  <button class="remove-item" @click="removeRide(index)">
                    <i class="fa fa-trash-o fa-lg"></i>
                  </button>
                </button>
              </div>
              <a
				v-if="isAuthenticated"
                href="/account/garage"
                class="btn btn-default btn-block manage-garage-button"
              >
                <i class="fa fa-fw fa-home"></i> Manage Garage
              </a>
			  <a 
			  	v-else 
				href="/account/login" 
				class="btn btn-default btn-block manage-garage-button"
			  >
				<i class="fa fa-fw fa-user"></i> Sign in for Savings
			  </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FitmentBar from "../fitment-bar/FitmentBar.vue";
import garage from "../../mixins/garage.js";
import axios from "axios";
import strings from "../../mixins/strings.js";

export default {
	name: "garage-menu",
	mixins: [garage, strings],
	components: { FitmentBar },

	props: {
		open: { type: Boolean, default: false },
		site: { type: String, default: "pz" },
		isAuthenticated: { type: Boolean, default: false },
		redirect: { type: Boolean },
		machineToken: { type: String },
		axiosBaseUrl: { type: String },
	},

	data() {
		return {
			clearing: false,
			updating: false,
		}
	},

	computed: {
		garageLabel() {
			if (this.hasStoredRide) return "My Garage";

			return `Select a ${this.capWords(this.vehicleType)}`;
		},
		vehicleType() {
			if (this.site === "pz") return "ride";
			if (this.site === "bn") return "engine";
		},
	},

	methods: {
		/**
		 * This function closes the modal & removes classes from the HTML element.
		 */
		close() {
			if (this.open) {
				this.$emit("toggle-garage");
				document.documentElement.classList.remove('menuOpen');
			}
		},
		/**
		 * This function sets the fitment the user as selected,
		 */
		selectedFitment() {
			this.getStoredRide();
			this.$emit("new-ride");

			this.$nextTick(() => {
				this.getStoredRide();
				this.checkForStoredRide();
				this.getLocalGarage();
				this.checkRedirect();
			});

			this.$emit("toggle-garage");
		},
		setRide(ride) {
			this.updating = true;
			
			this.setStoredRide(ride);

			this.updateSelected(ride);
			
			this.$emit("new-ride");

			// this.$emit("toggle-garage");
		},
		checkRedirect() {
			// if redirect is enabled
			if (this.redirect) {
				// is the user already on a catalog product page?
				if (window.location.pathname.includes('catalog-product')) {
					window.location.reload();
					return;
				}
				// relocate to catalog page specific for ride
				if (
					// check to make sure it's not already on a product page
					!window.location.pathname.includes('catalog-product')
				) {
					this.shopForCurrent();
				}
			} 
			// if redirect is disabled
			else {
				window.location.reload();
			}
		},
		updateSelected(ride) {
			/**
			 * instead of using the computed property: this.garageFitmentId 
			 * from the garage mixin,
			 * use the passed ride
			 */
			axios.post(`/garage/update/${ride.garage_id.fitment}`).then((results) => {
				this.checkForStoredRide();

				if (this.hasStoredRide) {
					this.getStoredRide();
				}
			}).catch((error) => {
				console.error('There was an error with the update garage request: ', error.message);
			}).finally(() => {
				// redirect
				this.checkRedirect();
			});
		},
		clearRide() {
			this.clearing = true;
			this.clearStoredRide();
			axios.get("/garage/clear").then(() => {
				this.removeFitmentParam();

				window.location.reload();
			});

			this.$emit("new-ride");
			this.close();

			this.$emit("toggle-garage");
		},
		removeRide(index) {
			if (confirm("Are you sure you want to delete this ride?")) {
				if (this.isAuthenticated) {
					this.deleteBackendRide(this.garageId !== null ? this.garageId : this.localGarage[index].garage_id.id);
				}

				this.removeLocalRide(index);
			}
		},
		async deleteBackendRide(fitment) {
			await axios
				.post("/account/garage/delete/" + fitment)
				.then((results) => {
					this.removeFitmentParam();
				})
				.catch((results) => {
					console.error('We\'ve run into an error in the request to delete the ride from the garage.');
				});
		},
		shopForCurrent() {
			if (this.hasStoredRide) {
				if (this.garageFitmentId) {
					window.location = `/catalog/v1/${this.garageFitmentId}/${this.storedRideName.replace(/[-]/g, '').replace(/\s/g, '-')}`;
				}
				return;
			}

			// alert('Please choose a ride to shop for ride!');
		},
		searchFromQuery() {
			let search = "/search?q=+fitment:" + this.garageFitmentId;

			window.location = search;
		},
		removeLocalRide(index) {
			this.localGarage.splice(index, 1);

			this.updateLocalGarage();
		},
	},

	mounted() {
		this.checkForStoredRide();

		if (this.hasStoredRide) {
			this.getStoredRide();
		}

		if (this.isAuthenticated) {
			this.getBackendGarage();
		} else {
			this.getLocalGarage();
		}
	},
};
</script>

<style lang="scss">
.manage-garage-button {
	font-weight: 700; 
	margin-top: 1em;
	text-transform: uppercase; 
}
div.garage-popup {
	position: fixed;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	overflow-y: scroll;

	z-index: 9999;

  // background-color: rgba(0,0,0,0.3);

	div.menu-shade {
		&.open {
			position: fixed;
			overflow-y: scroll;
			background-color: rgba(0, 0, 0, 0.45);
			pointer-events: all;
		}
	}

	div.popup-body {
		background-color: white;
		border-radius: 5px;
		box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
		max-width: 1200px;
		padding-bottom: 1em;
		position: absolute;
		top: 5%;
		width: 80%;
		z-index: 200;

		div.popup-top {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			padding: 1rem;
			width: 100%;

			button {
				align-items: center;
				background-color: transparent;
				border: none;
				border-radius: 9999px;
				display: flex;
				height: 24px;
				justify-content: center;
				outline: 0;
				transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
				width: 24px;

				&:hover {
					color: rgba(#666, 0.5);
					background-color: #f4f4f4;
				}
			}
		}

		div.popup-content {
			padding: 2em;
			background-color: #f4f4f4;
			display: flex;
			flex-direction: column;

			h3 {
				color: black;
				font-size: 2rem;
				font-weight: 700;
				margin: 0;
				text-transform: uppercase;
			}

			div.fitment-bar {
				border-radius: 5px;
				box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
			}
		}

		div.garage {
			padding: 2em 0;
			display: flex;

			h4 {
				font-weight: 700;
				text-transform: uppercase;
				color: black;
			}

			div.current {
				padding: 1em 2em;
				flex-basis: 50%;

				div.options {
					align-items: center;
					display: flex;
					flex-wrap: wrap;
					font-size: 1.2rem;
					padding-top: 1em;
					width: 100%;
					gap: 1rem;

					// button:first-child {
					// 	padding: 1em 1em 1em 0;
					// }

					// button:not(:first-child) {
					// 	padding: 1em;
					// }

					button:not(:last-child) {
						border-right: 1px solid #e3e3e3;
					}
				}
			}

			button.garage-item {
				outline: 0;
				border: none;
				border-left: 3px solid #e3e3e3;

				background-color: transparent;

				width: 100%;
				text-align: left;

				padding: 0;
				margin-bottom: 3px;

				display: flex;
				align-items: center;
				justify-content: space-between;

				font-weight: 700;

				div.item-name-container {
					width: 100%;

					span.item-name {
						display: block;
						padding: 1em;
						max-width: 300px;
						text-overflow: ellipsis;
						overflow-x: hidden;
						white-space: nowrap;
					}
				}

			button.remove-item {
				padding: 1em 1.5em;
				border: 0;
				outline: none;
				background-color: transparent;
				margin: 0;

				i {
					margin: 0;
				}

				&:hover {
					background-color: #666;
					color: white;
				}
			}

				&:hover {
					background-color: #f4f4f4;
					border-color: #666;
				}

				i {
					margin-right: 6px;
				}
			}

			div.garage-list {
				border-left: 1px solid #e3e3e3;
				display: flex;
				flex-basis: 50%;
				flex-direction: column;
				padding: 1em 2em;
				
				// if garage-list is the only child in the garage element
				&:only-child {
					margin-left: auto;
				}

				// if only one child within garage-list is available
				> :only-child {
					margin-top: auto;
				}

				div.garage-items {
					max-height: 220px;
					overflow-y: scroll;
				}
			}
		}
	}
}

@media (max-width: 920px) {
  div.garage-popup {
    div.popup-body {
      width: 95%;
    }
  }
}

@media (max-width: 768px) {
  div.garage-popup {

    div.popup-top {
      position: sticky;
      top: 0;
      background-color: white;
    }

    div.popup-body {
      width: 100%;
      height: 100%;
      top: 0;
      border-radius: 0;
      box-shadow: none;

      overflow-y: scroll;

      div.popup-content {
        align-items: center;
        justify-content: center;

        h3 {
          text-align: center;
          max-width: 260px;
        }

        p {
          text-align: center;
          max-width: 260px;
        }

        div.fitment-container {
          width: 100%;
        }
      }

      div.garage {
        flex-direction: column;

        div.current {
          flex-basis: unset;
        }

		.garage-list {
			&:only-child {
				margin-left: 0 !important;
			}
		}

        div.options {
          flex-basis: unset;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          button {
            width: 100%;
            border: none !important;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
</style>