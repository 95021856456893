<template>
  <button class="cart-button" @click="go()" :class="site">
    <i class="fa fa-fw fa-shopping-cart fa-2x"></i> 
    <span>{{ label }}</span>
    <span
      class="quantity"
      :class="{ stuck: stuck, bn: site === 'bn' }"
      v-if="quantity > 0"
      >{{ quantity }}</span
    >
  </button>
</template>

<script>
import money from "../../mixins/money.js";
import EventBus from "../../event-bus.js";
import axios from "axios";

export default {
  name: "cart-button",

  mixins: [money],

  props: {
    quantity: { default: 0 },
    items: { type: Array, default: () => [] },
    total: { default: 0 },
    stuck: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
    site: { type: String, default: "pz" },
  },

  computed: {
    label() {
      if (this.total > 0) {
        return "$" + this.money(this.total);
      }

      return "Cart";
    },
  },

  methods: {
    go() {
      if (window.innerWidth < 768 || this.quantity === 0) {
        window.location = "/cart";
        return;
      }

      this.$emit("toggle-cart");
    },
  },
};
</script>

<style lang="scss">
button.cart-button {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-size: 1.2rem;
  color: #fff;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  span.quantity {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 3px solid black;

    position: absolute;
    right: 0px;
    top: 6px;
    background: #c3161c;
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 50%;

    font-size: 1em;

    &.stuck {
      border-color: #252525;
      top: -4px;
    }

    &.bn {
      border-color: #3777bc;
      background-color: #ef6225;

      &.stuck {
        border-color: #ffffff !important;
      }
    }
  }

  &.pz:hover,
  &.pz:active {
    z-index: 1 !important;
    background: #222;
  }

  &.bn {
    color: #222;

    &:hover {
      color: lighten(#222, 15%);
    }

    span.quantity {
      border-color: white;
    }
  }

  span.quanity {
    border-color: #222;
  }
}

@media (max-width: 991px) {
  button.cart-button {
    &.bn {
      a {
        color: #3777bc;
      }
    }

    span.quantity {
      &.bn {
        border-color: white;
      }
    }
  }
}

@media (max-width: 540px) {
  button.cart-button {
    span.quantity {
      transform: scale(0.85);
      top: 4px;
      right: 5px;

      width: 22px;
      height: 22px;

      border: 2px solid black;
    }
  }
}
</style>