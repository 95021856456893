<template>
  <div class="garage-menu" :class="[siteTag, {stuck: stuck}]">
    <button
      class="garage-menu-button"
      :class="[siteTag, {stuck: stuck}]"
      @click="clicked()"
      @mouseenter="$emit('menu-hovered')"
      @mouseleave="$emit('menu-hovered')"
    >
      <div class="button-body">
        <i class="fa fa-fw fa-2x" :class="garageIcon"></i>
        <div class="garage-label" v-html="label"></div>
        <span class="glyphicon glyphicon-menu-right"></span>
      </div>
      <transition name="fade" mode="out-in">
        <div class="menu-body" v-if="hasDropdown">
          <div class="menu-body-content">
            <p>{{ tooltip }}</p>
            <button
              class="btn btn-default btn-sm btn-block"
              style="margin-top: 6px"
            >
              {{ cta }}
            </button>
          </div>
        </div>
      </transition>
    </button>
  </div>
</template>

<script>
import EventBus from "../../event-bus.js";
import garage from "../../mixins/garage.js";
import strings from "../../mixins/strings.js";

export default {
  name: "garage-menu-button",
  mixins: [garage, strings],

  props: {
    site: { type: String, default: "pz" },
    siteTag: { type: String, defualt: "pz" },
    updating: { type: Boolean, default: false },
    hasDropdown: { type: Boolean, default: false },
    eventCategory: { type: String, default: "Header" },
    stuck: { type: Boolean }
  },

  data() {
    return {
      popup: false,
    };
  },

  watch: {
    updating(val) {
      if (!val) return;

      console.log("Update selected fitment from fitment bar");
      this.getStoredRide();
    },
  },

  computed: {
    label() {
    //   if (this.hasStoredRide) return `${this.storedRide.year} ${this.storedRide.make} ${this.storedRide.model}`;
		if (this.hasStoredRide) return this.storedRideName;

		return "My Garage";
    },
    tooltip() {
      if (this.hasStoredRide) return `Edit or Change your ${this.vehicleType}s.`;

      return `Add or Select a ${this.capWords(this.vehicleType)} so you know your parts fit.`;
    },
    cta() {
      if (this.hasStoredRide) return "Edit or Change";

      return `Add ${this.capWords(this.vehicleType)}`;
    },
    vehicleType() {
      if (this.site === "pz") return "ride";
      if (this.site === "bn") return "engine";
    },
    garageIcon() {
      if (this.site === "pz") return "fa-motorcycle";
      if (this.site === "bn") return "fa-ship";
    }
  },

  methods: {
    selectedFitment() {
      this.popup = false;
      this.getStoredRide();
    },
    clicked() {
      this.$emit("toggle-garage");
	  document.documentElement.classList.add('menuOpen');
    },
  },

  mounted() {
    this.checkForStoredRide();

    if (this.hasStoredRide) {
      this.getStoredRide();
    }

    this.getLocalGarage();

    EventBus.$on("update-fitment", () => {
      this.checkForStoredRide();

      if (this.hasStoredRide) this.getStoredRide();
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss">
// for mobile menu
.utils {
  .garage-menu {
    padding: 1em !important;
    width: 100%;
    .garage-menu-button {
      .button-body {
        .fa {
          width: 100%;
        }
        .garage-label {
          width: 100%;
        }
        .glyphicon {
          width: 100%;
        }
      }
      &:hover {
        background: none;
        color: rgba(195, 22, 28, 1);
      }
    }
  }
}
div.garage-menu {
  text-align: center;
  align-self: stretch;
  display: flex;
  .garage-menu-button {
    border-radius: 4px;
    border: none;
    display: flex;
    padding: 0.5rem 0;
    position: relative;
    outline: 0;
    &.bn {
      background-color: #f3f3f3;
      color: #222;
      &:hover {
        background-color: transparent;
        color: lighten(#222, 15%);
      }
      &.stuck {
        &:hover {
          .menu-body {
            transform: translateY(4.5rem) scaleY(1);
            background: #f3f3f3;
          }
        }
      }
    }
    &.pz {
      color: #fff;
      background: transparent;
      &:hover {
        background: #222;
        color: #fff;
      }
      &.stuck {
        &:hover {
          .menu-body {
            transform: translateY(4.5rem) scaleY(1);
          }
        }
      }
      .menu-body > .menu-body-content > p {
        color: #fff;
      }
    }
    div.button-body {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: transparent;
      padding: 6px 12px;
      border: none;
      outline: 0;
      font-size: 1em;
      font-weight: 700;
      line-height: 1;
    }
    &:hover {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      div.menu-body {
        transition: all 100ms ease-in-out;
        opacity: 1;
        transform: translateY(9rem) scaleY(1);
      }
    }
    i {
      margin-right: 0.5rem;
    }
    span {
      margin-left: 1rem;
    }
    div.menu-body {
      position: absolute;
      left: 0;
      transition: all 100ms ease-in-out;
      opacity: 0;
      transform: translateY(-10px) scaleY(0);
      transform-origin: top center;
      background-color: #222;
      width: 240px;
      margin-top: 0px;
      border-radius: 5px;
      text-align: left;
      padding: 1rem;
      z-index: 1000;
      .menu-body-content {
        padding: 1rem;
        p {
          color: black;
        }
      }
    }
  }
}

@media screen and (max-width: 1194px) {
  div.garage-menu {
    margin: 0;
    min-width: 150px;
    .garage-menu-button {
      padding: 0 !important;
      margin: 0 auto;
      .button-body {
        padding: 0 !important;
      }
    }
  }
}

@media screen and (min-width: 1194px) {
  div.garage-menu {
    min-width: 200px;
  }
}

@media (max-width: 640px) {
  div.garage-menu {
    margin-right: 0;
    min-width: 110px;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.25s;
  transform: scale(1);
}
.modal-enter, .modal-leave-to /* .modal-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(1.3);
}
</style>
