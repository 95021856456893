<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 379.15 59.79">
    <g id="Layer_2" data-name="Layer 2">
      <path
        class="bn-1"
        d="M102.16,43H116.5C115.24,32,106,23.83,95,22.94V35.72C99,36.46,101.29,39,102.16,43Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-1"
        d="M90,35.93V23C79,24.27,70,33,68.74,43H83.08A9.48,9.48,0,0,1,90,35.93Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-1"
        d="M95,55.52V68.31C106,67.42,115.38,59,116.54,48H102.21C101.41,52,99,54.77,95,55.52Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-1"
        d="M83,48H68.71C69.83,59,79,67,90,68.23V55.31A9.8,9.8,0,0,1,83,48Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-1"
        d="M247.9,59.44a9,9,0,1,1,9,9A9,9,0,0,1,247.9,59.44Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-2"
        d="M148.56,67.42a28.57,28.57,0,0,1-.6-6.08c-3.43,5-8.31,6.94-14.31,6.94-8.14,0-15.26-4.46-15.26-13.2,0-6.94,5.4-11.57,11.49-13.46a74.24,74.24,0,0,1,17.22-2.74v-.34c0-3.94-1-5.49-5.14-5.49-3.34,0-6.17,1.2-6.68,4.89l-15.6-1.46c2.57-10.88,12.94-13.71,22.8-13.71,5.14,0,11.74.69,16,3.77,5.74,4,5.05,9.68,5.05,15.94V57.82c0,3.26.09,6.52,1.37,9.6ZM147.1,46.94c-4.11.43-11,1.71-11,7,0,2.83,1.8,4,4.46,4,6.43,0,6.51-5.41,6.51-10.2Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-2"
        d="M18.06,8.63H46.77c4.46,0,9,.34,12.69,3a15.46,15.46,0,0,1,6.26,12.42,12.83,12.83,0,0,1-9.95,12.78V37c6.77,1.19,11.4,6.34,11.4,13.37,0,5.06-1.8,10.71-6,13.71-4.54,3.26-10.88,3.34-16.2,3.34H18.06ZM41.88,31.17c1.72,0,3.78.08,5.06-1.12a5.3,5.3,0,0,0,1.72-3.85c0-4.37-3.6-5-7-5h-7v9.94Zm.52,23.14c1.8,0,3.77.08,5.23-1.11a5.13,5.13,0,0,0-.69-8.75,12.17,12.17,0,0,0-5.23-.77H34.6V54.31Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-2"
        d="M166.08,34.77V23.62h7.12l1.28-14,14.74-.51V23.62H198V34.77h-8.57V49.85c0,3.17-.34,5.66,3.69,5.66a19.69,19.69,0,0,0,4.88-.77V67l-10,1.29a13.82,13.82,0,0,1-12.25-4.46c-3.34-3.6-3.26-7.8-3.26-12.51V34.77Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-2"
        d="M213.39,53.2c1,4.2,5.66,4.71,9.34,4.71,1.72,0,6.52-.17,6.52-2.74,0-2.23-5.83-2.66-7.37-2.83-9.17-1.29-20.06-3.09-20.06-14.74,0-5,2.66-8.92,6.77-11.49,4.29-2.65,9.17-3.34,14.06-3.34,8.23,0,16.88,3.17,19.8,11.4L229.5,36.65c-1.28-3-4.62-3.86-7.62-3.86-1.37,0-5.49.43-5.49,2.32,0,1.37,1.46,2,2.57,2.23a68.31,68.31,0,0,0,7.38,1c3.93.51,8.39,1.11,11.91,3.25a12.72,12.72,0,0,1,6.17,11.15c0,12.08-12.77,15.51-22.63,15.51S203,65.11,199.76,55Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-2"
        d="M287.43,23.62V29.8h.17c2.06-5,7.11-7,12.43-7,4.11,0,8.83,1.2,11.4,4.54,2.74,3.6,2.74,8.57,2.74,13.11v27h-17V40.85c0-2.66,0-6.34-3.69-6.34-4.71,0-5.14,4-5.14,7.63V67.42h-17V23.62Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-2"
        d="M365.32,52.77c-2.66,10.63-12.08,15.51-22.45,15.51-6.43,0-12.68-1.37-17.66-5.74A21.89,21.89,0,0,1,318,46.08a24.42,24.42,0,0,1,6.43-16.54c4.46-4.63,11.14-6.77,17.4-6.77,14.57,0,23.48,9.43,23.48,23.83v2.31H336c-.26,4.71,1.46,8.14,6.6,8.14,3.86,0,6-1.54,6.68-5.31ZM348.18,40.34c0-4.29-1.37-7.55-6.17-7.55-4.46,0-6.26,3.52-6.09,7.55Z"
        transform="translate(-18.06 -8.63)"
      />
      <path
        class="bn-2"
        d="M365.32,34.77V23.62h7.12l1.28-14,14.75-.51V23.62h8.74V34.77h-8.57V49.85c0,3.17-.35,5.66,3.68,5.66a19.24,19.24,0,0,0,4.89-.77V67l-10,1.29a13.82,13.82,0,0,1-12.25-4.46c-3.35-3.6-3.26-7.8-3.26-12.51V34.77Z"
        transform="translate(-18.06 -8.63)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "bn-logo",
};
</script>

<style>
.bn-1 {
  fill: #ef6225;
}
.bn-2 {
  fill: #3777bc;
}
</style>