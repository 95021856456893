<template>
  <svg
    id="firedog_Image"
    data-name="firedog Image"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 468.67 96.67"
  >
    <path
      class="fd-1"
      d="M55.21,49.25a13.33,13.33,0,0,0-3.59,3.59c-2.8,3.85-5,8.09-7.43,12.14-4.31,7.07-9.27,13.59-16,18.61a32.41,32.41,0,0,1-17.87,6.89,11.85,11.85,0,0,1-3.71-.12v-.59c6.63-3.72,11.65-9.21,16.27-15.1a91.32,91.32,0,0,0,9.92-16.2,63.26,63.26,0,0,1,9.74-14.9,23.18,23.18,0,0,1,9-6.43c1.84-.68,3.62-1.49,5.43-2.25.29.41.12.83,0,1.23a39.2,39.2,0,0,0-1.52,11.75C55.54,48.35,55.63,48.87,55.21,49.25Z"
    />
    <path
      class="fd-2"
      d="M6.65,62.62a5.15,5.15,0,0,0,.72-2C9.28,55,13.27,52,19.1,51.2c2.92-.38,5.89-.23,8.77-1A47.47,47.47,0,0,0,33.5,48.5a1.47,1.47,0,0,1-.18,1.3,21.63,21.63,0,0,1-9,10.17,10.73,10.73,0,0,1-7,1.2A13.26,13.26,0,0,0,6.65,63.41Z"
    />
    <path
      class="fd-1"
      d="M129.45,18.54l13.57.11c1.42,0,1.42,0,1.62-1.36,0-.23.06-.46.11-.68.1-.47.44-1.19.27-1.35-.39-.36-1.06-.13-1.61-.13-2.75,0-5.5,0-8.26,0a7.63,7.63,0,0,1-2.88-.49c-1.33-.55-1.6-1.44-.64-2.52A42.78,42.78,0,0,1,136,8.3a12.07,12.07,0,0,1,5.15-2.94,7,7,0,0,1,4.4.55c1.68.73,3.35,1.49,5,2.24a7.3,7.3,0,0,0,5.53.28,17.07,17.07,0,0,1,13.69,1.29,11.62,11.62,0,0,1,5.31,5.48,15.77,15.77,0,0,0,6.48,7,66.58,66.58,0,0,0,8.19,4.42c1.91.81,2.34,2.16,1.23,3.88a27.27,27.27,0,0,1-5.33,6.39c-2.53,2.13-5.48,2.3-8.55,1.6-2.71-.62-5.39-1.38-8.15-1.76a13.82,13.82,0,0,0-10.76,2.63,7.61,7.61,0,0,0-3.09,6.57,5.41,5.41,0,0,0,1.59,3.41,9.87,9.87,0,0,0,7.24,3.23c3.47.17,6.93-.32,10.4-.43,4.35-.14,8.71-.3,13.06-.37,4.06-.06,8.13-.13,12.19.08a32.23,32.23,0,0,1,8.58,1.52,7.19,7.19,0,0,1,1,.4c1.1.55,2.23,1.15,2.28,2.56a3.82,3.82,0,0,1-2,3.49,6,6,0,0,1-5.85.26c-3.67-1.7-7.48-1.79-11.36-1.33Q181.35,60,170.61,62.23c-2.63.54-5,1.82-7.41,2.83a36.24,36.24,0,0,1-8.87,2.76,10.79,10.79,0,0,1-5-.43c-3.25-1.12-6.55-2.14-9.13-4.58-.27-.25-.45-.14-.68,0-.58.37-1.17.73-1.76,1.09a23.28,23.28,0,0,1-9.52,3.68,37.84,37.84,0,0,1-9.86,0c-3.45-.41-6.84-1.14-10.27-1.69a60.2,60.2,0,0,0-9.26-1,54.85,54.85,0,0,0-16.42,2.58c-1.16.36-2.29.8-3.45,1.13-.53,0-.83-.35-1.15-.67a28.65,28.65,0,0,1-8.3-17.59A30.27,30.27,0,0,1,70.13,41a4.05,4.05,0,0,1,.49-1.39A57.15,57.15,0,0,1,76,36.07a132.89,132.89,0,0,1,28.69-13.39c1.53-.5,3.07-.93,4.61-1.39,2.08-.46,4.14-1,6.22-1.43a77.23,77.23,0,0,1,9.82-1.21c1-.08,2-.19,3-.23A1.88,1.88,0,0,1,129.45,18.54Z"
    />
    <path
      class="fd-3"
      d="M239.55,19.56a16.92,16.92,0,0,0-3.49-1.11c-3-.56-5.08,1-5.34,4.07-.13,1.5-.13,1.5,1.34,1.5s3.21,0,4.82,0c.51,0,.75.07.74.68,0,2.78,0,5.57,0,8.35,0,.61-.23.68-.74.67-1.71,0-3.41,0-5.12,0-.86,0-1.14.17-1.13,1.1,0,7.64,0,15.27,0,22.91,0,.87-.18,1.17-1.11,1.15-3.71-.05-7.41-.05-11.12,0-.95,0-1.26-.17-1.26-1.21.06-7.64,0-15.28,0-22.91,0-.79-.14-1.08-1-1-1.76.08-3.54,0-5.3,0-.54,0-.74-.12-.73-.7,0-2.79,0-5.57,0-8.36,0-.53.18-.66.68-.65,1.8,0,3.6,0,5.41,0,.72,0,.94-.16.94-.92a27.66,27.66,0,0,1,.22-6.17,10.46,10.46,0,0,1,9.14-8.24c3.23-.45,6.4.06,9.59.31.92.07,1.83.22,2.74.26.53,0,.64.24.64.72C239.54,13.15,239.55,16.29,239.55,19.56Z"
    />
    <path
      class="fd-3"
      d="M269.87,31a33.26,33.26,0,0,1,4.41-5.31c3.9-3.82,9.82-3,13.35-1.05,1.39.76,1.39,1,.72,2.37-1.55,3.27-3.13,6.54-4.65,9.83-.28.6-.43.77-1.05.3-4.49-3.41-10.28-1.79-12.46,3.46a4.34,4.34,0,0,0-.33,1.71c0,5.22,0,10.43,0,15.64,0,.71-.13,1-.9.94q-5.79-.06-11.6,0c-.76,0-.91-.22-.9-.94q0-16.82,0-33.63c0-.69.1-1,.89-.95,3.87.05,7.73.05,11.6,0,.75,0,.94.19.92.92C269.83,26.38,269.87,28.51,269.87,31Z"
    />
    <path
      class="fd-3"
      d="M253.72,41.06c0,5.6,0,11.21,0,16.81,0,.69-.09,1-.89,1-3.9,0-7.8,0-11.7,0-.62,0-.84-.13-.84-.81q0-16.91,0-33.83c0-.7.22-.87.89-.86,3.86,0,7.73,0,11.6,0,.83,0,.94.3.94,1C253.7,29.91,253.72,35.48,253.72,41.06Z"
    />
    <path
      class="fd-2"
      d="M56.57,58.62a44.41,44.41,0,0,0,8.76,16.81c-6.21,4.47-12.22,9.22-19,12.85a26.46,26.46,0,0,1-16,3.32c-1.14-.14-2.29-.12-3.42-.34-.26-.05-.65-.07-.68-.44s.28-.43.5-.56a6,6,0,0,1,.86-.47c6.16-2.5,10.89-6.81,15-11.91C47.09,72.16,51.09,66,55.44,60.09Z"
    />
    <path
      class="fd-3"
      d="M247.17,20.33a5.44,5.44,0,0,1-5.48-5.58,5.53,5.53,0,1,1,11.06.09A5.46,5.46,0,0,1,247.17,20.33Z"
    />
    <path
      class="fd-4"
      d="M461.19,28c.38-1.08.71-2,1-2.92.12-.34.16-.73.67-.66s.31.5.32.77c0,1,0,2,0,3,0,.23,0,.49-.34.46s-.21-.28-.22-.45a18.25,18.25,0,0,0-.14-2.65c-.3.84-.58,1.69-.9,2.53-.08.22-.07.57-.42.58s-.41-.36-.5-.62a14.63,14.63,0,0,0-1-2.45v2.56c0,.22,0,.51-.3.5s-.25-.31-.26-.51c0-1.08,0-2.16,0-3.24,0-.24-.07-.54.36-.53s.53,0,.65.42C460.43,25.87,460.79,26.85,461.19,28Z"
    />
    <path
      class="fd-5"
      d="M456.74,28.79a1.59,1.59,0,0,1-1.62-.84c-.09-.16-.23-.37,0-.52s.39,0,.51.22c.35.69,1,.69,1.61.57a.63.63,0,0,0,.58-.58.6.6,0,0,0-.49-.65c-.43-.14-.89-.23-1.31-.39a1.06,1.06,0,0,1-.83-1.18,1.11,1.11,0,0,1,1.06-1,1.67,1.67,0,0,1,1.84.61c.11.16.3.41,0,.58s-.37,0-.46-.23c-.27-.56-.78-.55-1.27-.49a.65.65,0,0,0-.62.54c0,.36.19.54.51.62l.85.23c.68.21,1.35.45,1.27,1.36C458.29,28.37,457.63,28.81,456.74,28.79Z"
    />
    <path
      class="fd-3"
      d="M136.75,64.26a36.45,36.45,0,0,1-4.64,2.1c-3.7,1.33-7.54,1.39-11.41,1.21-.78,0-1.55-.11-2.33-.17a2.7,2.7,0,0,0-.9.59A28.21,28.21,0,0,1,80,69.2a2.79,2.79,0,0,0-1.16-.71,26.5,26.5,0,0,1-6.91-9.74,27.62,27.62,0,0,1-1.08-19.14,27.3,27.3,0,0,1,13-16.54,27.6,27.6,0,0,1,25.43-1.54h0a3.39,3.39,0,0,0,1.26-.19,65.55,65.55,0,0,1,10.77-2c2.74-.23,5.47-.51,8.2-.77-.61-.63-1.19-1.3-1.83-1.9-10-9.33-21.89-13.28-35.45-11.44S68.53,14,61.34,25.47A47.43,47.43,0,0,0,57,34.89a46.21,46.21,0,0,0-1.82,14.36,45.46,45.46,0,0,0,1.36,9.37,1.5,1.5,0,0,0,0,1,42.36,42.36,0,0,0,8,15.34c.19.23.36.47.69.51.16.16.33.31.48.48,10.52,11.44,23.52,16.45,38.87,14,15.65-2.51,26.57-11.54,33-26C137.32,63.81,137.05,64.11,136.75,64.26Z"
    />
    <path
      class="fd-2"
      d="M453,25.38c0-.79-.23-1-1-1-7.57,0-15.14-.05-22.72,0A20.81,20.81,0,0,0,415,29.77c-3.68,3.34-5.39,7.47-4.09,12.46,1,3.86,3.62,6.52,6.93,8.58a28.75,28.75,0,0,0,3.47,1.72,16.18,16.18,0,0,0-3.06,1.1,4.48,4.48,0,0,0-2.48,4.53,9.36,9.36,0,0,0,1.53,4.4c.59.9.33,1.17-.48,1.62a15,15,0,0,0-6.18,5.88,7.41,7.41,0,0,0,.92,8.69,13.85,13.85,0,0,0,6,3.94c7.09,2.54,14.21,2.24,21.32.09a22.38,22.38,0,0,0,10.36-6.11c3.9-4.26,3.43-9.25-1.17-12.74a15,15,0,0,0-3.33-1.88,30.3,30.3,0,0,0-15.13-1.86c-1.3.14-1.38.1-1.42-1.23A6,6,0,0,1,430,54.81a2.34,2.34,0,0,1,1.77-.84,21.46,21.46,0,0,0,13.82-5.58A12.06,12.06,0,0,0,447.31,32c-.12-.18-.35-.31-.28-.66,1.65,0,3.31,0,5,0,.7,0,1-.12.95-.9C452.89,28.79,452.89,27.08,453,25.38ZM431.46,68A9.61,9.61,0,0,1,437.25,70a3.88,3.88,0,0,1,.41,6.06c-3.15,3.2-10.32,3.23-13.53.06a3.8,3.8,0,0,1,.16-6C425.85,68.76,428.26,68,431.46,68Zm-.79-22.26a6.62,6.62,0,0,1-6.53-6.6,6.57,6.57,0,1,1,13.14.08A6.61,6.61,0,0,1,430.67,45.71Z"
    />
    <path
      class="fd-2"
      d="M365.42,12.22c-3.83,0-7.66,0-11.5,0-.82,0-1,.22-1,1,0,4.49,0,9,0,13.62a21.22,21.22,0,0,0-11.44-2.43c-3.85.12-7.54.93-10.47,3.56-5.24,4.71-6.31,10.87-4.87,17.35,1.63,7.38,9.76,13.48,17.69,13.92a13.59,13.59,0,0,0,9.09-2.61,6.35,6.35,0,0,1,0,1c-.16,1,.25,1.18,1.18,1.17,3.8-.06,7.6,0,11.41,0,.67,0,.89-.17.88-.87q0-22.42,0-44.84C366.39,12.31,366.1,12.21,365.42,12.22ZM352.71,48.33c-1.54,1.43-3.17,2.75-5.39,2.69a7.71,7.71,0,0,1-7.68-6.18c-.55-2.7-.74-5.38.57-8a7.65,7.65,0,0,1,12.26-2.18,1.36,1.36,0,0,1,.46,1.11c0,2,0,3.93,0,5.9v5.7C352.92,47.73,353,48.06,352.71,48.33Z"
    />
    <path
      class="fd-3"
      d="M323.36,33.38c-1.78-5.88-5.53-9.66-11.64-10.92a26.4,26.4,0,0,0-10.44.06c-6.21,1.2-10.86,4.5-13.55,10.33a26.14,26.14,0,0,0-2.19,9.3A15.3,15.3,0,0,0,290,54.21c3.6,3.74,8.24,5,13.25,5.23a67.46,67.46,0,0,0,12.93-.77,23.71,23.71,0,0,0,6.94-2c.32-.16.72-.27.71-.81,0-2.75,0-5.5,0-8.33a51.54,51.54,0,0,1-9.91,2.6c-3.39.49-6.79.7-10.09-.5a7.75,7.75,0,0,1-5.13-5.7h1.13c7.8,0,15.6,0,23.4,0,.85,0,1.13-.23,1.17-1.05A28.88,28.88,0,0,0,323.36,33.38ZM303,38.23c-1.4,0-2.81,0-4.22,0-.72,0-.8-.24-.7-.84a6.63,6.63,0,0,1,5.73-5.87c3.65-.56,6.06.63,7.61,3.75a11.52,11.52,0,0,1,1,2.94Z"
    />
    <path
      class="fd-2"
      d="M403.09,29.59a22.9,22.9,0,0,0-29.95.72,15.68,15.68,0,0,0-1,22.18c4.38,4.84,10,6.84,16.15,7a22.75,22.75,0,0,0,14.27-4.82C410.92,48,411.15,36.41,403.09,29.59Zm-9.85,19.24c-3.17,2.84-7.41,2.47-10.25-.82a10.4,10.4,0,0,1,.46-13.56c3.32-3.36,8.42-2.78,11.15,1.26a10.51,10.51,0,0,1,1.74,5.92A9.71,9.71,0,0,1,393.24,48.83Z"
    />
  </svg>
</template>

<script>
export default {
  name: "fd-logo",
};
</script>

<style>
.fd-1 {
  fill: #33ac00;
}
.fd-2 {
  fill: #33ac00;
}
.fd-3 {
  fill: #3d3d3d;
}
.fd-4 {
  fill: #33ac00;
}
.fd-5 {
  fill: #33ac00;
}
</style>