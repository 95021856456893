<template>
  <div>
    <span v-if="loading">
      <i class="fa fa-spinner fa-spin text-muted"></i>
    </span>

    <div v-if="!loading && product" class="result-card">
      <product-card
        :product="product"
        :site="site"
        :cda-level="cda"
        :auth="auth"
        event-category="Typeahead Result Card"
        :add-min="addMin"
        :add-max="addMax"
        :holiday="holiday"
        :dc="dc"
      />
    </div>
    <span v-if="!loading && !product">
      <a
        :href="fullPartUrl"
        class="btn btn-block btn-sm srch-track-view-product"
        >View Product</a
      >
    </span>
  </div>
</template>

<script>
import axios from "axios";
import Pricing from "../../components/product-card/Pricing.vue";
import Stock from "../../components/product-card/Stock.vue";
import AddToCart from "../../components/product-card/AddToCart.vue";
import Strings from "../../mixins/strings.js";
import Product from "../../mixins/product.js";
import Garage from "../../mixins/garage.js";
import ProductCard from "../../components/product-card/ProductCard.vue";

export default {
  name: "product-result",
  mixins: [Strings, Product, Garage],
  components: { ProductCard, Pricing, Stock, AddToCart },

  props: {
    docKey: { type: String, default: "/" },
	docProductName: { type: String },
	docProductId: {  },
    auth: { type: Number },
    cda: { type: Number },
    site: { type: String },
    domain: { type: String },
    addMin: { type: String },
    addMax: { type: String },
    holiday: { type: Boolean },
    dc: {},
  },

  data() {
    return {
      loading: true,
      product: null,
      qty: 1,
    };
  },

  computed: {
    productId() {
      if (this.hasField("store_product_id")) {
        return this.product.store_product_id;
      } else if (this.hasField("product_id")) {
        return this.product.product_id;
      } else if (this.hasField("price_file")) {
        return this.product.price_file.id;
      } else {
        return null;
      }
    },
    productName() {
      if (this.hasField("name")) {
        return this.product.name;
      } else {
        return this.product.product_name;
      }
    },
    eventName() {
      if (this.hasField("is_collection")) {
        if (this.product.is_collection)
          return "Collection: " + this.productName;
      }

      return this.productName;
    },
    productImage() {
      if (this.hasField("entity")) {
        return this.product.entity.image[0];
      } else if (this.hasField("product_image")) {
        return this.product.product_image;
      }
    },
    vendor() {
      let vendor = "";

      if (
        this.product.vendor &&
        Object.keys(this.product.vendor).includes("name")
      ) {
        vendor = this.product.vendor.name;
      }

      if (this.hasField("entity") && this.product.entity.manufacturer) {
        vendor = this.product.entity.manufacturer[0];
      } else if (
        this.hasField("manufacturer") &&
        this.product.manufacturer != null
      ) {
        vendor = this.product.manufacturer;
      } else {
        vendor = this.product.vendor;
      }

      return this.capWords(vendor);
    },
    fullPartUrl() {
      if (this.breadcrumbRef) {
        let key = "/" + this.docKey;
        let delim = this.docKey.includes("?") ? "&" : "?";
        let ref = "ref=" + this.breadcrumbRef;

        return key + delim + ref;
      } else {
        return "/" + this.docKey;
      }
    },
    // docKey() {
    //   if (this.hasField("doc_key")) {
    //     return this.product.doc_key;
    //   } else {
    //     return this.product.product_doc_key;
    //   }
    // },
    isOutboard() {
      if (this.hasField("price_file")) {
        return this.product.price_file.type_id === "3";
      } else {
        return this.product.type_id === "3";
      }

      if (this.hasField("entity")) {
        return this.product.entity.product_type[0] === "3";
      }
    },
    // productFits() {
    //   if (this.selectedFitment !== null && this.hasField("fitment")) {
    //     return this.selectedFitment.catalog.fitment === this.product.fitment;
    //   }

    //   return false;
    // },
  },

  methods: {
    hasField(field) {
      return Object.keys(this.product).includes(field);
    },
    qtyChange(val) {
      this.qty += val;

      if (this.qty <= 0) {
        this.qty = 1;
      }
    },
    getProduct() {
			let uri = "";
	
			if (window.location.hostname.includes("localhost")) {
				uri += "https://www.partzilla.com";
			} else {
				// uri += this.domain;
			}
	
			uri += "/" + this.docKey + "?api=1";
	
			axios
				.get(uri)
				.then((result) => {
					this.loading = false;
					console.log(result);
					
					if (result.data.product) {
						this.product = result.data.product;
					} else {
						this.product = result.data;
					}
				})
        .catch((result) => {
          this.loading = false;
        });
    },
  },


  beforeMount() {
	this.checkForStoredRide();

	if (this.hasStoredRide) {
		this.getStoredRide();
	}
  },

  mounted() {
    this.getProduct();
  },
};
</script>

<style lang="scss">
div.result-card {
  display: flex;
  flex-direction: column;
  //   align-items: center;
  color: #666 !important;

  padding: 12px;

  div.product-image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 18%;
    min-height: 130px;
  }

  div.product-name-container {
    padding: 0;
  }

  div.product-body {
    .product-name {
      font-weight: bold;
    }

    .product-card-divider {
      width: 100%;
      height: 0;
      border-bottom: 1px solid #d8d8d8;
      margin: 12px 0;
    }
  }

  div.cart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div.productQty {
      margin-top: 0.25em;
    }
  }
}
</style>
