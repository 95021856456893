export default {
    methods: {
        parseBase64(data) {
            let json;

            try {
				let decoded = atob(data);
                json = JSON.parse(decoded);
            } catch (err) {
                json = data;
            }

            return json;
        },
    },
};