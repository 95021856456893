<template>
  <svg
    id="pz"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 5260.1 794.15"
  >
    <rect class="pz-1" x="25.72" y="38.44" width="316.77" height="703.97" />
    <polygon
      class="pz-1"
      points="461.98 517.09 390.1 517.09 390.1 38.44 798.33 38.44 461.98 517.09"
    />
    <polygon
      points="360.43 742.41 857.06 38.44 1093.39 38.44 1093.39 130.93 1093.39 207.53 728.09 742.41 360.43 742.41"
    />
    <polygon
      points="1094.78 742.41 1094.78 390.41 1024.72 390.41 783.77 742.41 1094.78 742.41"
    />
    <path
      d="M1252.29,109.86h227.12c92.78,0,175.18,0,224.77,71.17,21.61,32,32,77.59,32,116,0,31.22-6.4,61.59-19.18,88-48.82,100.8-152.79,103.18-229.55,104.78h-93.59V674.55H1252.29Zm141.56,104V385h84.79c52.79,0,116.75-1.59,116.75-88.79,0-82.37-71.17-82.37-112-82.37Z"
    />
    <path
      d="M2007.43,109.86l208,564.69H2065.05l-28-90.37h-208l-28,90.37H1650.75l209.56-564.69Zm-76,134.39c-7.22,28.77-15.21,58.37-23.2,87.18-12,42.38-33.61,108.76-46.39,151.15h143.18Z"
    />
    <path
      d="M2223.4,109.86h254.33c59.2.8,132.77,1.59,184.75,57.59,26.4,29.6,44.81,74.38,44.81,125.56,0,113.58-77.58,141.58-115.94,156l130.33,225.56H2565.73L2453.76,473H2365V674.55H2223.4ZM2365,215.44V368.19h116.75c20-.79,84.82-2.37,84.82-77.57,0-72-52-74.37-81.58-75.18Z"
    />
    <path d="M2709.65,109.06h435.1V221H2998.37V674.55H2856.84V221H2709.65Z" />
    <path
      d="M3183.93,109.86h455.9V220.24L3331.9,563.36h306.32V674.55H3157.53V569l312.73-348.73H3183.93Z"
    />
    <path d="M3817.37,109.86V674.55H3676.58V109.86Z" />
    <path d="M3865.31,109.06H4006.1V555.35h220.75v119.2H3865.31Z" />
    <path d="M4266.8,109.06h140.79V555.35h220.76v119.2H4266.8Z" />
    <path
      d="M5001.82,109.86l208,564.69H5059.41l-27.95-90.37h-208l-28,90.37H4645.1l209.56-564.69Zm-76,134.39c-7.18,28.77-15.17,58.37-23.19,87.18-12,42.38-33.58,108.76-46.36,151.15h143.15Z"
    />
  </svg>
</template>

<script>
export default {
  name: "pz-logo",
};
</script>

<style>
.pz-1 {
  fill: #c3161c;
}

svg#pz {
  fill: white;
}
</style>